$xxl-width: 1600px;
$full-width: 1300px;
$xl-width: 1200px;
$lg-width: 992px;
$md-width: 768px;
$sm-width: 576px;
$mobile-width: 450px;
$xs-width: 400px;

@mixin full {
  @media (max-width: #{$full-width - 1px}) {
    @content;
  }
}
@mixin xxl {
  @media (max-width: #{$xxl-width - 1px}) {
    @content;
  }
}
@mixin xl {
  @media (max-width: #{$xl-width - 1px}) {
    @content;
  }
}
@mixin xl {
  @media (max-width: #{$xl-width - 1px}) {
    @content;
  }
}

@mixin lg {
  @media (max-width: #{$lg-width - 1px}) {
    @content;
  }
}

@mixin md {
  @media (max-width: #{$md-width - 1px}) {
    @content;
  }
}

@mixin sm {
  @media (max-width: #{$sm-width - 1px}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$mobile-width - 1px}) {
    @content;
  }
}

@mixin xs {
  @media (max-width: #{$xs-width - 1px}) {
    @content;
  }
}
