@use "~mixins" as *;
.studio-stats {
  padding: clamp(50px, 9vw, 130px) 0 clamp(10px, 0.5vw, 10px) 0;
  overflow: hidden;
  .studio-stats__button {
    .btn-outline a {
      color: #171717;
      border-color: #fff;
      background: #fff;
    }
  }
  @include xl {
    padding: clamp(70px, 10.5vw, 170px) 0 0 0;
  }

  &__zoom {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 999;
    cursor: pointer;
    margin: 1.2vw;
    background: var(--color-orange-300);
    border-radius: 0.6vw;
    width: 2vw;
    height: 2vw;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s ease;
    display: none;
    &:hover {
      transform: scale(1.1);
    }
    @include md {
      width: 32px;
      height: 32px;
      margin: 12px;
      border-radius: 8px;
    }
    svg {
      width: 0.9vw;
      transform: rotate(45deg);
      height: 0.9vw;
      stroke-width: var(--f-button-svg-stroke-width);
      stroke: var(--color-white);
      display: block;
      @include md {
        width: 14px;
        height: 14px;
      }
    }
  }
  &__arrow {
    width: 45px;
    height: 45px;
    z-index: 999;
    position: absolute;
    top: calc(50% - 40px);
    transform: translate(-50%, 0);
    border-radius: 50%;
    display: none;
    left: 5px;
    justify-content: center;
    align-items: center;
    transition: 0.8s cubic-bezier(0.38, 0.005, 0.215, 1);
    background: var(--color-white);

    &.swiper-button-disabled {
      opacity: 0;
      visibility: hidden;
    }
    &.next {
      left: auto;
      right: -20px;
      svg {
        transform: rotate(180deg);
      }
    }
    svg {
      width: 13px;
      height: 13px;
    }
  }
  &__bloggers {
    // pointer-events: none;
    margin-top: 2vw;
    width: 100%;
    @include md {
      padding: 0 29px 0 0;
      overflow: initial !important;
      pointer-events: inherit;
      margin-top: 18px;
      padding-bottom: 42px;
    }
    .swiper-horizontal > .swiper-scrollbar,
    .swiper-scrollbar.swiper-scrollbar-horizontal {
      width: 9vw;
      // left: 26vw;
      left: 50%;
      background: var(--scroll-bg);
      position: absolute;
      height: 0.1vw;
      transform: translate(-50%, 0);
      bottom: 0vw;
      display: none;
      @include md {
        display: flex;
        height: 2px;
        width: 110px;
        bottom: 0px;
        opacity: 1 !important;
      }
      .swiper-scrollbar-drag {
        background: var(--color-dark);
      }
    }
    .swiper-wrapper {
      display: grid;
      grid-gap: clamp(10px, 1vw, 19px);
      grid: auto / repeat(4, 1fr);
      @include md {
        display: flex;
      }
    }
    .swiper-slide {
      position: relative;
      border-radius: 1vw;
      overflow: hidden;
      width: 100% !important;
      @include md {
        width: 80% !important;
        border-radius: 12px;
      }
      p {
        padding: 0.8vw;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 9;
        font-size: 1vw;
        color: var(--color-white);
        @include md {
          font-size: 24px;
          padding: 20px;
        }
      }
      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        left: 0;
        background: rgba(0, 0, 0, 0.2);
      }
      &::before {
        content: "";
        display: block;
        padding-bottom: 100%;
        @include md {
          padding-bottom: 105%;
        }
      }
      img {
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
      }
    }
  }
  &__mores {
    display: grid;
    grid: auto / 1fr;
    grid-gap: 2.5vw;
    margin-top: 3.5vw;
  }
  &__name {
    @include md {
      display: flex;
      left: -18px;
      position: relative;
      width: calc(100% + 36px);

      border-radius: 16px;
      border: 1.5px solid var(--color-dark-450);
      min-height: 80px;
      padding: 0 24px;
      align-items: center;
      justify-content: space-between;
    }

    h3 {
      font-size: clamp(18px, 1.1vw, 20px);
      line-height: clamp(26px, 1.65vw, 31px);
      font-weight: 400;
      position: relative;
      max-width: fit-content;

      span {
        position: absolute;
        font-size: 0.8vw;
        top: -0.5vw;
        display: block;
        color: #9f9f9f;
        opacity: 0.6;
        left: calc(100% + 0.5vw);
        @include md {
          font-size: 12px;
          top: -6px;
          left: calc(100% + 7px);
        }
      }
    }
  }

  &__information {
    padding: clamp(24px, 2.5vw, 48px);
    transition-delay: 1.3s !important;

    &.mobile {
      display: none;
      @include md {
        display: flex;
      }
    }
    &-row {
      display: flex;
      align-items: center;
      grid-gap: clamp(8px, 1vw, 19px);
      margin-top: clamp(16px, 1vw, 19px);
      flex-wrap: wrap;
      justify-content: center;
      @include md {
        grid-gap: 8px;
        margin: 0;
      }
    }
    .studio-stats__button {
      svg {
        stroke: var(--color-dark);
      }
      .btn-outline a {
        background: transparent;
        color: var(--color-dark);
        border-color: var(--color-dark);
      }
    }
    @include md {
      padding: 22px 18px;
    }
    h2 {
      font-size: clamp(26px, 2vw, 36px);
      line-height: clamp(34px, 2.6vw, 50px);
      font-weight: 500;
      text-transform: inherit;
      margin-bottom: clamp(32px, 2.6vw, 50px);
      @include md {
        + .studio-stats__button.dark {
          display: none;
        }
      }
    }
  }
  &__main {
    display: grid;
    grid: auto / 34vw 1fr;
    height: 100%;
    grid-gap: 1vw;
    grid-gap: clamp(32px, 2vw, 36px);
    @include lg {
      grid: auto / repeat(1, 1fr);
    }
  }
  &__slider {
    overflow: inherit !important;
    width: 100%;
    .swiper-slide div {
      width: 100%;
      height: 100%;
    }
    &.js-stats-slider-horisontal {
      // .swiper-slide {
      //   a {
      //     position: relative;
      //     &::before {
      //       content: "";
      //       display: block;
      //       padding-bottom: 25vw;
      //     }
      //     img {
      //       width: 100%;
      //       height: 100%;
      //       display: block;
      //       object-fit: cover;
      //       top: 0;
      //       left: 0;

      //       position: absolute;
      //       top: 0;
      //       bottom: 0;
      //     }
      //   }
      // }
    }
    .swiper-slide {
      height: auto;
    }
    .swiper-wrapper {
      margin-bottom: 2vw;
      @include md {
        margin-bottom: 24px;
      }
    }
    .swiper-scrollbar {
      height: 0.12vw;
      width: 8vw;
      left: 50%;
      position: absolute;
      transform: translate(-50%, 0);
      opacity: 1 !important;
      bottom: 0;
      background: var(--scroll-bg);
      @include md {
        height: 2px;
        width: 110px;
      }
      .swiper-scrollbar-drag {
        height: 0.12vw;
        background: var(--color-dark);
        @include md {
          height: 2px;
        }
      }
    }
    @include md {
      padding: 0 18px;
    }
    .swiper-slide {
      // height: auto;
      img {
        width: 100%;
        object-position: top;
        object-fit: cover;
        height: 100%;
      }
    }
  }
  &__inner {
    display: flex;
    flex: 1;
    align-items: center;
    padding: clamp(8px, 1.1vw, 19px) 0;
    justify-content: space-between;
    @include md {
      padding: 8px 0;
    }
    p {
      font-size: clamp(22px, 1.4vw, 32px);
      color: var(--color-dark);
      font-weight: 400;
      line-height: 1;
      @include md {
        font-size: 20px;
        line-height: 30px;
      }
    }
    span {
      font-size: clamp(22px, 1.4vw, 32px);
      color: var(--color-dark);
      font-weight: 400;
      line-height: 1;
      @include md {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }
  &__desc {
    font-size: 1.5vw;
    font-weight: 400;
    line-height: 2vw;
    display: none;
    margin-bottom: 2vw;
    @include md {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 32px;
    }
    span {
    }
  }
  &__section {
    margin-top: 4vw;
    display: grid;
    grid: auto / 1fr;

    grid-gap: 2vw;
    @include md {
      grid-gap: 16px;
      margin-top: 42px;
    }
  }
  &__social {
    display: grid;
    align-items: flex-start;
    grid: auto / repeat(2, 1fr);
    grid-gap: clamp(16px, 5vw, 100px);
    border-radius: 1.5vw;
    background: var(--color-gray-450);
    padding: clamp(24px, 2.5vw, 48px);
    position: relative;
    @include lg {
      grid: auto / repeat(1, 1fr);
    }
    @include md {
      overflow: hidden;
      width: calc(100% + 44px);
      left: -22px;
      padding: 0 0 50px 0;
      grid: auto / repeat(1, 1fr);
      border-radius: 12px;
      grid-gap: 16px;
      .studio-stats__button.dark img {
        filter: var(--filter-invert);
      }
    }

    .studio-stats__button {
      svg {
        stroke: var(--color-dark);
      }
      .btn-outline a {
        background: transparent;
        color: var(--color-dark);
        border-color: var(--color-dark);
      }
    }
    h2 {
      font-size: clamp(26px, 2vw, 36px);
      line-height: clamp(34px, 2.6vw, 50px);
      font-weight: 500;
      text-transform: inherit;
    }
    &-main {
      max-width: 80%;
      @include xl {
        max-width: 100%;
      }
      @include md {
        padding: 32px 22px;
      }
    }
    &-info {
      display: flex;
      margin-bottom: clamp(32px, 2vw, 36px);
      align-items: center;
      justify-content: space-between;
    }
  }
  &__overlay {
    position: relative;
    width: 100%;
    border-radius: 1vw;
    overflow: hidden;
    @include md {
      border-radius: 16px;
    }
    &::before {
      content: "";
      display: block;
      padding-bottom: 70%;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }
  }
  &__ingrid {
    &-box {
      p {
        font-size: clamp(15px, 0.95vw, 18px);
        font-weight: 400;
        line-height: clamp(22px, 2vw, 36px);
        color: #9f9f9f;
        @include md {
          order: 1;
        }
      }

      h3 {
        font-size: clamp(16px, 1.2vw, 21px);
        line-height: 1.3;
        font-weight: 400;
        @include md {
          order: 1;
        }
      }
    }
    .studio-stats__button {
      &.dark {
        .btn-outline {
          display: flex;
          + img {
            display: block;
          }
        }
      }
      svg {
        stroke: var(--color-dark);
      }
      .btn-outline {
        a {
          background: transparent;
          color: var(--color-dark);
          border-color: var(--color-dark);
          @include md {
            font-size: 15px;
            border-radius: 8px;
            line-height: 30px;
          }
        }
      }
    }
    h2 {
      font-size: clamp(26px, 2vw, 36px);
      line-height: clamp(34px, 2.6vw, 50px);
      font-weight: 500;
      text-transform: inherit;
    }
    &-item {
      display: flex;
      margin-top: clamp(24px, 1.2vw, 24px);
      grid-gap: clamp(24px, 2vw, 36px);
      align-items: flex-start;
      justify-content: space-between;
    }

    &-row {
      display: grid;
      grid: auto / repeat(2, 1fr);
      grid-gap: clamp(24px, 1.5vw, 28px);
      margin-top: clamp(24px, 2.5vw, 50px);
      @include xl {
        grid: auto / 1fr;
      }
      @include lg {
        grid: auto / repeat(2, 1fr);
      }
      @include md {
        grid-gap: 42px;
        grid: auto / 1fr;
      }
    }
  }
  &__initem {
    border-radius: clamp(16px, 1.1vw, 20px);
    background: var(--color-gray-450);
    padding: clamp(24px, 2.5vw, 48px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .studio-stats__button {
      &.dark {
        .btn-outline {
          display: flex;
          + img {
            display: block;
          }
        }
      }
      svg {
        stroke: var(--color-dark);
      }
      .btn-outline {
        a {
          background: transparent;
          color: var(--color-dark);
          border-color: var(--color-dark);
          @include md {
            font-size: 15px;
            border-radius: 8px;
            line-height: 30px;
          }
        }
      }
    }
    h2 {
      font-size: clamp(26px, 2vw, 36px);
      line-height: clamp(34px, 2.6vw, 50px);
      font-weight: 500;
      text-transform: inherit;
    }
  }
  &__inrow {
    display: grid;
    grid-gap: clamp(16px, 2vw, 36px);
    grid: auto / 1fr 30vw;
    margin-top: clamp(32px, 2vw, 36px);
    position: relative;

    @include lg {
      display: flex;
      flex-direction: column;
      grid: auto / repeat(1, 1fr);
    }
  }
  &__grid {
    display: grid;
    grid-gap: clamp(16px, 2vw, 36px);
    grid: auto / repeat(3, 1fr);
    margin-top: clamp(24px, 2vw, 36px);
    position: relative;
    @include lg {
      grid: auto / repeat(2, 1fr);
    }
    @include md {
      grid: auto / repeat(1, 1fr);
      .studio-stats__button .btn-outline {
        display: flex;
      }
    }
    &-info {
      display: flex;
      grid-gap: 3vw;
      width: 100%;
      justify-content: space-between;
      @include md {
        grid-gap: 24px;
      }
    }
    &-item {
      border-radius: clamp(16px, 1.1vw, 20px);
      background: var(--color-gray-450);
      padding: clamp(24px, 2.5vw, 48px);
      display: flex;
      position: relative;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      min-height: clamp(200px, 12vw, 240px);
      @include md {
        min-height: auto;
      }
      .studio-stats__button.dark {
        @include md {
          margin-top: 32px;
        }
      }
      .studio-stats__button {
        &.dark {
          .btn-outline {
            display: flex;
            + img {
              display: block;
            }
          }
        }
        svg {
          stroke: var(--color-dark);
        }
        .btn-outline {
          a {
            background: transparent;
            color: var(--color-dark);
            border-color: var(--color-dark);
          }
        }
      }
      h2 {
        font-size: clamp(26px, 2vw, 36px);
        line-height: clamp(34px, 2.6vw, 50px);
        font-weight: 500;
        text-transform: inherit;
      }
    }
    @include md {
      grid-gap: 16px;
    }
  }
  &__line {
    width: 100%;
    height: 1px;
    display: block;
    margin: 0.9vw 0;
    @include md {
      margin: 8px 0;
    }
    span {
      font-size: 1.1vw;
      line-height: 1.5vw;
    }
    &.man {
      background: var(--color-blue);
      + span {
        color: var(--color-blue);
      }
    }
    &.woman {
      background: var(--color-rose);
      + span {
        color: var(--color-rose);
      }
    }
  }
  &__gender {
    display: flex;
    margin-top: clamp(24px, 2.5vw, 40px);
    grid-gap: clamp(16px, 1vw, 19px);
    &-item {
      width: 100%;
    }

    span {
      font-size: clamp(16px, 1.1vw, 20px);
      line-height: clamp(24px, 1.5vw, 28px);
      font-weight: 400;
      color: var(--color-dark);
    }
  }

  &__more {
    display: none;
  }
  &__text {
    border-radius: clamp(16px, 1.1vw, 20px);
    background: var(--color-gray-450);
    padding: 2.5vw;
    margin: 0;
    flex: 1;
    color: var(--color-dark);
    display: flex;
    align-items: center;
    font-size: clamp(16px, 1vw, 18px);
    line-height: 1.4;
    font-weight: 400;
    span {
      border-bottom: 1px solid var(--color-dark);
    }
    @include md {
      order: 1;
      padding: 0;
      background: transparent;
    }
  }
  &__nav {
    list-style-type: none;
    margin: 0;
    padding: 0;
    flex: 1;
    @include md {
      border-radius: 16px;
      padding: 0;
    }
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:last-child {
        .studio-stats__inner {
          border: none;
        }
      }
      a {
        display: flex;
        grid-gap: 2vw;
        align-items: center;
        span {
          &:nth-child(1) {
            color: #ccc;
            font-size: clamp(14px, 0.9vw, 18px);
            font-style: normal;
            font-weight: 400;
            line-height: 2;
          }
        }
      }
    }
  }
  &__inlist {
    list-style-type: none;
    margin: 0;

    grid-gap: clamp(6px, 1vw, 14px);
    display: flex;
    flex-direction: column;
    background: var(--color-gray-450);
    border-radius: clamp(16px, 1.1vw, 20px);
    padding: clamp(24px, 2.5vw, 48px);
    position: relative;
    @include md {
      order: 3;
    }
    li {
      &:hover img {
        transform: scale(1.1);
      }
      img {
        width: clamp(24px, 2vw, 36px);
        transition: 0.4s ease;
        height: clamp(24px, 2vw, 36px);
      }
      &:last-child {
        a {
          border: none;
        }
        .studio-stats__inner {
          border: none;
        }
      }
      a {
        display: flex;
        grid-gap: clamp(18px, 2vw, 36px);
        padding: 0 1vw;
        border-bottom: 1px solid var(--color-dark-520);
        align-items: center;
        transition: 0.8s cubic-bezier(0.38, 0.005, 0.215, 1);
        img {
          filter: var(--filter-button);
        }

        &:hover {
          border-color: var(--color-dark);
        }
        span {
          &:nth-child(1) {
            color: #ccc;
            font-size: clamp(14px, 0.9vw, 18px);
            font-style: normal;
            font-weight: 400;
            line-height: 2;
          }
        }
      }
    }
  }
  &__button {
    display: flex;
    grid-gap: 0;
    align-items: center;
    &.hide {
      opacity: 0;
      visibility: hidden;
    }
    button {
      border: none;
    }
    &.dark {
      margin: 0;
      justify-content: flex-start;
      img {
        filter: contrast(0.88);
      }
      .btn-outline {
        a,
        button {
          color: var(--color-white);
          border-color: var(--color-dark-500);
          background: var(--color-dark-500);
        }
      }
    }
    a,
    button {
      &.mobile {
        @include md {
          width: 32px;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        display: none;
        @include md {
          display: block;
        }
      }
    }
    .btn-outline {
      @include md {
        display: none;
        + img {
          display: none;
        }
      }
      a,
      button {
        font-size: clamp(14px, 0.8vw, 16px);
        line-height: clamp(34px, 2.4vw, 40px);
        padding: 0 clamp(24px, 1.8vw, 32px);
        border-radius: clamp(8px, 0.5vw, 10px);
        min-width: 6vw;
        text-transform: inherit;
        color: var(--color-inwhite);
        border-color: var(--color-white);
        background: transparent;

        @include lg {
          border-width: 1px;
          border-radius: 8px;
        }
      }
    }
    &:hover svg {
      opacity: 0.9;
    }
    @include md {
      grid-gap: 0;
    }
    img {
      width: clamp(36px, 2.4vw, 42px);
      height: clamp(36px, 2.4vw, 42px);
      z-index: 0;
      filter: var(--filter-invert);
      margin-left: -0.05vw;
      transition: 0.4s ease;
      object-fit: cover;
      @include lg {
        margin-left: -1px;
      }
    }
  }

  &__inmain {
    overflow: hidden;
    width: 100%;
    @include md {
      overflow: inherit;
      .studio-stats__social-info {
        padding: 0 18px;
      }
    }
  }
  &__tag {
    grid-gap: clamp(10px, 0.5vw, 12px);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0;
    margin: clamp(40px, 2.5vw, 50px) 0 0;

    li {
      border-radius: clamp(8px, 0.4vw, 9px);
      display: flex;
      font-size: clamp(14px, 0.85vw, 16px);
      line-height: clamp(28px, 2.4vw, 32px);
      justify-content: center;
      align-items: center;
      padding: 0 0.6vw;
      border: 1px solid var(--color-dark);
      color: var(--color-dark);
      padding: 0 clamp(16px, 0.8vw, 19px);
    }
  }
  &__box {
    padding-right: 5vw;
    padding-top: 0.5vw;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    &.collaboration {
      padding-right: 0;
    }
    &.music {
      padding-right: 0;
      img {
        width: 1.1vw;
        transition: 0.4s ease;
        position: relative;
        left: 0.3vw;
        height: 1.1vw;
      }
      ul li {
        cursor: pointer;
        transition: 0.4s ease;
        &:hover {
          background: var(--color-dark);
          color: var(--color-white);
          img {
            filter: brightness(0) invert(1);
          }
        }
      }
    }
    @include md {
      padding-top: 8px;
      border: none;
      padding-right: 0;
    }
  }
  &__info {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    grid-gap: clamp(16px, 1vw, 20px);
    align-items: center;
    justify-content: space-between;
    padding: clamp(24px, 1.5vw, 30px);
    position: absolute;
    bottom: 0;
    left: 0;
    @include md {
      .studio-stats__button {
        display: none;
      }
    }
  }
  &__title {
    font-size: clamp(32px, 2.2vw, 42px);
    color: var(--color-white);
  }
  &__picture {
    border-radius: 1vw;
    overflow: hidden;
    background: var(--color-gray-450);
    position: relative;
    .studio-stats__button img {
      filter: brightness(0) invert(1);
    }
    video {
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
    }
    &::before {
      content: "";
      display: block;
      padding-bottom: 100%;
      @include lg {
        padding-bottom: 50%;
      }
      @include md {
        padding-bottom: 100%;
      }
    }
    @include md {
      border-radius: 16px;
    }

    img,
    video {
      &.picture {
        object-position: 0 0;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        display: block;
        object-fit: cover;
      }
    }
  }
  &__wrap {
    display: flex;
    grid-gap: clamp(32px, 2vw, 36px);
    flex-direction: column;
  }
  &__price {
    display: none;
    transition: 0.8s cubic-bezier(0.38, 0.005, 0.215, 1);
    opacity: 0;
    visibility: hidden;
    &.hide {
      opacity: 1;
      visibility: visible;
    }
    @include md {
      order: 2;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 16px;
      // border-top-left-radius: 16px;
      // border-top-right-radius: 16px;
      position: fixed;
      bottom: 0;
      z-index: 999;
      left: 0;
      width: calc(100% - 44px);
      background: var(--color-gray-450);
      z-index: 99;
      min-height: 60px;
      padding: 0 24px;
      margin: 16px 22px;
      .studio-stats__button .btn-outline {
        display: flex;
      }

      .btn-outline a {
        background: transparent;
        line-height: 28px;
        font-size: 13px;
        border-radius: 8px;
        color: var(--color-dark);
        border-color: var(--color-dark);
      }
      &-title {
        font-size: 18px;
        color: var(--color-dark);
      }
    }
  }
  &__row {
    display: grid;
    grid: auto / repeat(2, 1fr);
    grid-gap: clamp(32px, 2.5vw, 40px);
    margin-top: clamp(24px, 4vw, 70px);
    @include md {
      grid: auto / repeat(1, 1fr);
    }
    h3 {
      font-size: clamp(20px, 1.6vw, 30px);
      margin-top: clamp(16px, 1vw, 19px);
      line-height: clamp(30px, 1.65vw, 31px);
      font-weight: 400;
      top: clamp(5px, 1vw, 19px);
      position: relative;
      grid-gap: clamp(6px, 0.5vw, 10px);
      max-width: fit-content;
      display: flex;
      align-items: center;

      &:hover img {
        transform: scale(1.05);
      }
      img {
        width: clamp(24px, 1.6vw, 30px);
        height: clamp(24px, 1.6vw, 30px);
        transition: 0.4s ease;
        left: clamp(10px, 0.5vw, 10px);
        filter: contrast(0.7);
        position: relative;
        display: block;
        object-fit: contain;
      }
    }
    h4 {
      font-size: clamp(16px, 0.9vw, 19px);
      grid-gap: clamp(16px, 1vw, 19px);
      font-weight: 400;
      text-transform: inherit;
      color: var(--color-dark);
      line-height: clamp(22px, 1.5vw, 30px);
      @include md {
        display: flex;
        grid-gap: 10px;
        white-space: nowrap;
        align-items: center;
        &::after {
          content: "";
          display: block;
          width: 100%;
          height: 1px;
          background: var(--color-gray-450);
        }
      }
    }
    ul {
      grid-gap: clamp(6px, 0.5vw, 10px);
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 0;
      margin: clamp(24px, 2vw, 36px) 0 0;
      @include md {
        margin: 0;
      }
      &.music {
        grid-gap: 2vw;
        li {
          background: transparent;
          flex-direction: column;
          align-items: flex-start;
          @include md {
            padding: 0;
            margin-bottom: 16px;
          }
          a {
            border-radius: 0.5vw;
            display: flex;
            font-size: 0.85vw;
            line-height: 1.7vw;
            justify-content: center;
            align-items: center;
            padding: 0 0.6vw;
            color: var(--color-dark);
            background: var(--color-gray-450);

            @include md {
              border-radius: 7.5px;
              line-height: 19px;
              font-size: 14px;
              padding: 6px 16px;
              margin-top: 12px;
            }
          }
        }
      }
      &.brand {
        grid-gap: clamp(12px, 1vw, 19px);
        grid: auto/repeat(4, 1fr);
        display: grid;
        @include xl {
          grid: auto/repeat(3, 1fr);
        }

        li {
          padding: 0;
          position: relative;
          border-radius: 50%;
          background: var(--color-gray-460);
          @include md {
            line-height: 19px;
            font-size: 14px;
          }
          &::before {
            content: "";
            display: block;
            padding-bottom: 100%;
          }
        }
        img {
          position: absolute;
          top: 50%;
          filter: invert(1) brightness(0);
          left: 50%;
          transform: translate(-50%, -50%);
          display: block;
          object-fit: contain;
          width: 65%;
          height: clamp(50px, 3.5vw, 70px);
          @include md {
            width: 70%;
          }
          &.f-black {
            filter: invert(1) brightness(1);
          }
        }
        span {
          display: none;
        }
      }
      @include md {
        padding: 18px 0 0;
        grid-gap: 10px;
      }
      li {
        border-radius: clamp(8px, 0.5vw, 10px);
        display: flex;
        font-size: clamp(14px, 0.85vw, 16px);
        line-height: clamp(28px, 1.8vw, 32px);
        justify-content: center;
        align-items: center;
        padding: 0 clamp(16px, 0.8vw, 19px);
        color: var(--color-dark);
        background: var(--color-gray-450);
      }
    }
  }
}
