.peaches-form {
  position: fixed;

  width: 100%;
  height: 100vh;
  top: 0;

  z-index: 99999;
  // padding: 3vw 0;
  overflow: auto;
  display: grid;
  align-items: center;

  left: 0;
  &__inwrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 1vw;
  }
  &__card {
    padding: 1vw 2vw;
    border-radius: 1vw;
    line-height: 1.5vw;
    position: relative;

    border: 1px solid rgb(37, 37, 37);
    span {
      border-width: 1px;
      border-style: solid;
      padding: 0 0.5vw;
      position: relative;
      font-size: 0.7vw;
      margin-right: 0.4vw;
      margin-top: 0.5vw;
      max-width: -moz-fit-content;
      max-width: fit-content;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      line-height: 1.4vw;

      border-radius: 1vw;
      img {
        position: static;
        width: 0.85vw;
        margin-left: 0.4vw;
      }
    }
    &-close {
      width: 1vw;
      cursor: pointer;
      height: 1vw;
      position: absolute;
      top: 0.8vw;
      object-fit: contain;
      right: 0.8vw;
      filter: brightness(0) invert(1);
    }
  }
  &__row {
    display: grid;
    grid: auto / repeat(3, 1fr);
    grid-gap: 1vw;
    font-size: 0.9vw;
    margin-bottom: 0;

    color: var(--color-white);
  }
  &__checkbox {
    width: 3vw;
    height: 3vw;
  }

  &__events {
    display: flex;
    align-items: center;
    grid-gap: 1vw;
  }
  &__inbox {
    border-radius: 0.5vw;
    display: flex;
    max-width: fit-content;
    flex-direction: row !important;
    align-items: center;
    grid-gap: 1vw;
    padding: 0 0 0 1.5vw !important;
    background: rgb(76, 1, 1);

    .css-1m9pwf3 {
      width: 2vw;
      height: 2vw;
    }
    .peaches-form__subhead {
      margin-bottom: 0;
      color: var(--color-white);
    }
  }
  &__inboxes {
    border-radius: 0.5vw;
    display: flex;
    flex-direction: row !important;
    align-items: center;
    grid-gap: 1vw;
    padding: 0 0 0 1.5vw !important;
    background: var(--color-dark-700);
    &:nth-child(2) {
      background: rgb(0, 106, 0);
    }
    &:nth-child(3) {
      background: rgb(145, 95, 2);
    }
    &:nth-child(4) {
      background: #01588f;
    }
    &:nth-child(5) {
      background: rgb(160, 0, 160);
    }
    .css-1m9pwf3 {
      width: 2vw;
      height: 2vw;
    }
    .peaches-form__subhead {
      margin-bottom: 0;
      color: var(--color-white);
    }
  }
  &__boxes {
    width: 100%;

    height: 100%;
    background: var(--color-dark-350);
    min-height: 100%;
    padding: 1.2vw;
    border-radius: 0.7vw;
    .peaches-form__row {
      overflow: auto;
      height: 100%;
      padding: 0 1vw 0 0;
      max-height: 29.5vw;
      &::-webkit-scrollbar {
        width: 0.2vw;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background: rgb(49, 49, 49);
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: rgb(57, 57, 57);
        border-radius: 10px;
      }
    }

    .peaches-form__subhead {
      font-size: 0.9vw;
      margin-bottom: 0;

      color: var(--color-white);
      span {
        border-width: 1px;
        border-style: solid;
        padding: 0 0.5vw;
        font-size: 0.78vw;
        max-width: fit-content;
        display: inline-block;
        margin: 0.3vw;
        line-height: 1.4vw;
        border-radius: 1vw;
      }
    }
  }
  &__title {
    font-size: 1.5vw;
    line-height: 2vw;
    color: var(--color-dark);
    display: block;
    text-align: center;
    font-weight: 500;
    margin-bottom: 1vw;
  }
  &__list {
    border-radius: 0.5vw;

    grid-gap: 1vw;
    padding: 1.5vw;
    margin-top: 1.9vw;
    background: var(--color-dark-700);

    color: var(--color-white);
    display: grid;
    grid-gap: 0.5vw;
    grid: auto / repeat(3, 1fr);
    .peaches-form__card-close {
      top: 50%;
      transform: translate(0, -50%);
      width: 1.3vw;
      height: 1.3vw;
    }
    li {
      font-size: 1vw;
      line-height: 1.2vw;
      background: rgb(50, 50, 50);
      font-weight: 500;
      position: relative;
      padding: 1vw 4vw 1vw 1vw;
      border-radius: 0.5vw;
      display: flex;
      grid-gap: 1vw;
      justify-content: space-between;
    }
  }
  &__head {
    font-size: 1.5vw;
    line-height: 2vw;
    border-radius: 0.5vw;

    grid-gap: 1vw;
    padding: 1.5vw;
    margin-top: 1.9vw;
    background: var(--color-dark-700);

    color: var(--color-white);

    font-weight: 500;
    margin-bottom: 1vw;
  }
  &__close {
    width: 2vw;
    height: 2vw;
    position: absolute;
    top: 2vw;
    z-index: 88;
    right: 2vw;
    img {
      width: 100%;
      filter: var(--filter-invert);
      display: block;
      object-fit: contain;
    }
    // filter: invert(1);
  }
  &__columns {
    display: grid;
    width: 100%;
    grid: auto / repeat(2, 1fr);
    grid-gap: 1.5vw;
    align-items: flex-start;
    label {
      color: var(--color-dark);
      svg {
        fill: var(--color-dark);
      }
    }
  }
  &__column {
    display: grid;
    grid: auto / 1fr;
    width: 100%;
    grid-gap: 1.5vw;
    align-items: flex-start;
    &-row {
      width: 100%;
      display: grid;
      align-items: flex-end;
      grid: auto / repeat(2, 1fr) 10vw;
      grid-gap: 1.5vw;
      margin-bottom: 1vw;
    }
  }
  &__content {
    display: grid;
    grid: auto / 19.5vw 1fr;
    grid-gap: 1.5vw;
    align-items: flex-start;
  }
  &__grid {
    display: grid;
    align-items: flex-start;
    grid: auto / 25vw 1fr;
    grid-gap: 3vw;

    .css-1vooibu-MuiSvgIcon-root {
      width: 1.1vw;
      height: 1.1vw;
      @include lg {
        width: 20px;
        height: 20px;
      }
    }
    .css-1cw4hi4 {
      width: 1.1vw;
      height: 1.1vw;
      @include lg {
        width: 20px;
        height: 20px;
      }
    }
    .css-1vooibu-MuiSvgIcon-root {
      width: 1.1vw;
      height: 1.1vw;
      @include lg {
        width: 20px;
        height: 20px;
      }
    }
    .css-nk89i7-MuiPickersCalendarHeader-root {
      padding-left: 0.8vw;
      padding-right: 0;
      margin: 0.8vw 0 1vw 0.5vw;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include lg {
        margin: 12px 0;
        padding: 0 16px;
      }
    }

    .css-1c32n2y-MuiBadge-root {
      width: 3vw;
      height: 3vw;
      flex: 0 0 3vw;
      @include lg {
        width: 40px;
        height: 40px;
        flex: 0 0 40px;
      }
    }
    .css-qa7bje-MuiButtonBase-root-MuiPickersDay-root {
      transition: 0.4s ease;
      &:hover {
        background-color: var(--color-dark-700) !important;
      }
    }
    .css-1hrukl9 {
      transition: 0.4s ease;
      &:hover {
        background-color: var(--color-dark-700) !important;
      }
    }
    .css-qa7bje-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
      background-color: var(--color-dark-700) !important;
    }
    .css-1hrukl9.Mui-selected {
      background-color: var(--color-dark-700) !important;
    }
    .css-qa7bje-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover {
      background-color: var(--color-dark-700) !important;
    }
    .css-1hrukl9.Mui-selected:hover {
      background-color: var(--color-dark-700) !important;
    }
    .css-cyfsxc-MuiPickersCalendarHeader-labelContainer {
      color: var(--color-white);
      font-size: 1.3vw;
      font-family: "Nunito Sans", sans-serif;

      font-weight: 400;
      line-height: 2vw;
      @include lg {
        font-size: 20px;
        line-height: 26px;
      }
    }
    .css-16j77m4 {
      color: var(--color-white);
      font-size: 1.3vw;
      font-family: "Nunito Sans", sans-serif;

      font-weight: 400;
      line-height: 2vw;
      @include lg {
        font-size: 20px;
        line-height: 26px;
      }
    }
    .css-5m299p-MuiButtonBase-root-MuiPickersDay-root {
      color: rgba(255, 255, 255, 0.2);
      font-size: 0.85vw;
      font-family: "Nunito Sans", sans-serif;
      width: 3vw;
      height: 3vw;
      flex: 0 0 3vw;
      @include lg {
        width: 40px;
        height: 40px;
        flex: 0 0 40px;
        font-size: 14px;
      }
    }
    .css-xl8mvw {
      color: rgba(255, 255, 255, 0.2);
      font-size: 0.85vw;
      font-family: "Nunito Sans", sans-serif;
      width: 3vw;
      height: 3vw;
      flex: 0 0 3vw;
      @include lg {
        width: 40px;
        height: 40px;
        flex: 0 0 40px;
        font-size: 14px;
      }
    }
    .css-qa7bje-MuiButtonBase-root-MuiPickersDay-root {
      color: var(--color-white);
      font-size: 0.85vw;
      font-family: "Nunito Sans", sans-serif;
      width: 3vw;
      height: 3vw;
      flex: 0 0 3vw;
      @include lg {
        width: 40px;
        height: 40px;
        flex: 0 0 40px;
        font-size: 14px;
      }
    }
    .css-1hrukl9 {
      color: var(--color-white);
      font-size: 0.85vw;
      font-family: "Nunito Sans", sans-serif;
      width: 3vw;
      height: 3vw;
      flex: 0 0 3vw;
      @include lg {
        width: 40px;
        height: 40px;
        flex: 0 0 40px;
        font-size: 14px;
      }
    }

    .css-1jsy6pn-MuiButtonBase-root-MuiPickersDay-root {
      color: var(--color-white);
      font-size: 0.85vw;
      border: 1px solid var(--color-dark-600);
      font-family: "Nunito Sans", sans-serif;
      background-color: transparent !important;
      width: 3vw;
      height: 3vw;
      flex: 0 0 3vw;
      @include lg {
        width: 40px;
        height: 40px;
        flex: 0 0 40px;
        font-size: 14px;
      }
    }
    .css-4ggly9 {
      color: var(--color-white);
      font-size: 0.85vw;
      border: 1px solid var(--color-dark-600);
      font-family: "Nunito Sans", sans-serif;
      background-color: transparent !important;
      width: 3vw;
      height: 3vw;
      flex: 0 0 3vw;
      @include lg {
        width: 40px;
        height: 40px;
        flex: 0 0 40px;
        font-size: 14px;
      }
    }

    .css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel {
      width: 3vw;
      height: 3vw;
      flex: 0 0 3vw;
      color: var(--color-white);
      font-size: 0.85vw;
      font-family: "Nunito Sans", sans-serif;
      @include lg {
        width: 40px;
        height: 40px;
        flex: 0 0 40px;
        font-size: 14px;
      }
    }
    .css-10qkmc3 {
      width: 3vw;
      height: 3vw;
      flex: 0 0 3vw;
      color: var(--color-white);
      font-size: 0.85vw;
      font-family: "Nunito Sans", sans-serif;
      @include lg {
        width: 40px;
        height: 40px;
        flex: 0 0 40px;
        font-size: 14px;
      }
    }
    .css-1nkg345-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button {
      border: 1px solid var(--color-white);
      border-radius: 50%;
      width: 2vw;
      height: 2vw;
      @include lg {
        width: 32px;
        height: 32px;
      }
    }
    .css-k9reix {
      color: var(--color-white);
    }

    .css-1bvpx9d {
      width: 100%;
      height: 100%;
      max-height: 20vw;
    }
    .css-1bqdsf2 {
      width: 100%;
      height: 100%;
      font-size: 0.8vw;
      margin: 0.4vw 0;
    }
    .css-1fklenr {
      border: 1px solid var(--color-white);
      border-radius: 50%;
      width: 2vw;
      height: 2vw;
      @include lg {
        width: 32px;
        height: 32px;
      }
    }
    .css-kg9q0s-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button {
      border: 1px solid var(--color-white);
      border-radius: 50%;
      width: 2vw;
      height: 2vw;
      margin-right: 0.1vw;
      @include lg {
        width: 32px;
        margin-right: 1px;
        height: 32px;
      }
    }
    .css-11wxb {
      border: 1px solid var(--color-white);
      border-radius: 50%;
      width: 2vw;
      height: 2vw;
      margin-right: 0.1vw;
      @include lg {
        width: 32px;
        margin-right: 1px;
        height: 32px;
      }
    }
    .css-1cafy48-MuiPickersSlideTransition-root-MuiDayCalendar-slideTransition {
      overflow: inherit;
      min-height: 100%;
    }
    .css-2ldko6 {
      overflow: inherit;
      min-height: 100%;
    }
    .css-1vooibu-MuiSvgIcon-root {
      fill: var(--color-white);
    }
    .css-1cw4hi4 {
      fill: var(--color-white);
    }
    .css-cwhad8-MuiDateCalendar-root {
      width: 100%;
      height: 100%;
      background: var(--color-dark-350);
      height: 30vw;
      min-height: 100%;
      max-height: 100%;
      padding: 1.2vw;
      border-radius: 1vw;
      @include lg {
        height: 440px;
      }
    }
    .css-1080di7 {
      width: 100%;
      height: 100%;
      background: var(--color-dark-350);
      height: 30vw;
      min-height: 100%;
      max-height: 100%;
      padding: 1.2vw;
      border-radius: 1vw;
      @include lg {
        height: 440px;
      }
    }
    .css-1jsy6pn-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected) {
      border-color: var(--color-dark-700) !important;
    }
    .css-4ggly9:not(.Mui-selected) {
      border-color: var(--color-dark-700) !important;
    }
    .css-qa7bje-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
      background-color: var(--color-dark-700) !important;
    }
    .css-1hrukl9.Mui-selected {
      background-color: var(--color-dark-700) !important;
    }
    .css-5m299p-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover {
      background-color: var(--color-dark-700) !important;
    }
    .css-5m299p-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover {
      background-color: var(--color-dark-700) !important;
    }
    .css-i5q14k-MuiDayCalendar-header {
      width: 100%;
      margin: 0.8vw 0 0 0;
      justify-content: space-between;
      @include lg {
        margin-top: 15px;
      }
    }
    .css-1n2mv2k {
      width: 100%;
      margin: 0.8vw 0 0 0;
      justify-content: space-between;
      @include lg {
        margin-top: 15px;
      }
    }
    .css-flbe84-MuiDayCalendar-weekContainer {
      width: 100%;
      margin: 0.4vw 0;
      justify-content: space-between;
      @include lg {
        margin: 15px 0;
      }
    }
    .css-mvmu1r {
      width: 100%;
      margin: 0.4vw 0;
      justify-content: space-between;
      @include lg {
        margin: 15px 0;
      }
    }
    .css-12p2adl-MuiDayCalendar-monthContainer {
      overflow: inherit;
    }
  }
  &__subhead {
    font-size: 0.8vw;
    margin-bottom: 0.4vw;
    line-height: 1.5vw;
    color: var(--color-dark);
  }
  &__main {
    max-width: 100%;
    width: 100%;
    display: flex;
    height: 100vh;
    position: relative;
    background: var(--color-dark-200);
    flex-direction: column;
    grid-gap: 1vw;
    overflow: auto;
    padding: 4.5vw 2.5vw;

    margin: 0 auto;

    .MuiFormControl-root css-1nrlq1o-MuiFormControl-root {
      flex: 1;
      width: 100%;
    }
    .css-v4u5dn-MuiInputBase-root-MuiInput-root {
      color: var(--color-white);
    }
    textarea.ant-input {
      border: 1px solid #dbdbdb;
      overflow: hidden;
      border-radius: 0.6vw;
      font-size: 0.9vw;
      min-height: 10vw;
      height: 12vw;
      background: transparent;
      color: var(--color-dark);
      padding: 0.6vw 1vw;
      transition: 0.4s ease;
      &::placeholder {
        color: #989898;
      }
      &:focus {
        border-color: var(--color-dark-900);
        outline: none;
        box-shadow: none;
      }
    }
    input {
      height: 3vw;
      border: 1px solid #dbdbdb;
      overflow: hidden;
      border-radius: 0.6vw;
      font-size: 0.9vw;
      color: var(--color-dark);
      padding: 0 1vw;
      transition: 0.4s ease;
    }
    input:focus {
      border-color: var(--color-black);
    }
    .css-v4u5dn-MuiInputBase-root-MuiInput-root {
      &:before {
        display: none;
      }
      &::after {
        display: none;
      }
    }
  }
  &__button {
    font-size: 0.9vw;
    width: 100%;
    line-height: 3vw;
    border-radius: 0.6vw;
    text-transform: inherit;
    color: var(--color-white);
    border: none;
    background: var(--color-black);
    transition: 0.4s ease;
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }

    @include md {
      border-width: 1px;
      line-height: 30px;
      border-radius: 8px;
      padding: 0 16px;
      font-size: 14px;
    }
  }
  &__overlay {
    left: 0;
    top: 0;
    right: 0;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 100vh;
    z-index: -1;

    background-color: rgba(0, 0, 0, 0.8);
  }
}
