@use "~mixins" as *;

.peaches-studio {
  padding: clamp(70px, 8vw, 130px) 0 clamp(30px, 4vw, 76px) 0;
  @include xl {
    padding: clamp(70px, 9.5vw, 130px) 0 clamp(30px, 4vw, 76px) 0;
  }
  &__inwrap {
    display: flex;
    align-items: center;
    grid-gap: 1vw;
    @include xl {
      grid-gap: 24px;
    }
  }
  .studio-stats__mores {
    @include xl {
      margin-top: 0;
      grid-gap: 16px;
    }
  }
  .studio-stats__button .btn-outline a {
    @include xl {
      background: var(--color-white);
      color: var(--color-dark);
    }
  }
  .studio-stats__information-row {
    margin: 0;
    padding: 0;
    @include xl {
      width: calc(200% + 1vw);
      display: flex;
      align-items: center;
    }
    @include lg {
      width: 100%;
    }
    @include md {
      width: calc(200% + 16px);
    }
  }
  .studio-stats__mores {
    margin: 0;
    grid-gap: 0;
  }
  .studio-stats__information {
    // transition-duration: 1.15s;
    padding: 0;
    border-color: var(--color-gray-450);
    background: var(--color-gray-450);
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 1vw;
    width: 100%;
    height: 100%;
    @include xl {
      background: transparent;
      border-color: white;
      width: calc(200% + 12px);
    }
    @include lg {
      width: 100%;
    }

    .studio-stats__button {
      margin: 0 auto;
      justify-content: center;
      @include xl {
        margin: 16px auto 0;
      }
    }
    .studio-stats__button.dark .btn-outline {
      + img {
        // filter: invert(1);
        // opacity: 0.15;
        filter: contrast(0.8);
        @include xl {
          opacity: 1;

          filter: invert(0.15);
          display: block !important;
        }
      }
      a {
        background: transparent;
        color: var(--color-dark);
        border-color: rgba(51, 51, 51, 1);
      }
    }
    .studio-stats__button .btn-outline {
      a {
        width: 100%;
        @include xl {
          color: var(--color-dark);
          border-color: var(--color-dark);
          background: var(--color-dark);
        }
      }
    }

    @include xl {
      border-radius: 14px;
      border: none;
      .btn-outline + img {
        display: none !important;
      }
      .studio-stats__button.dark .btn-outline a {
        // @include xl {
        //   white-space: nowrap;
        //   text-overflow: ellipsis;
        //   background-color: var(--color-white) !important;
        //   color: var(--color-dark) !important;
        // }
      }
      .studio-stats__button a.mobile {
        @include xl {
          display: none;
        }
      }
    }
  }
  .studio-stats__name {
    @include xl {
      width: 100%;
      position: static;
      grid-gap: 10px;
      min-height: auto;
    }
    .studio-stats__button.dark .btn-outline {
      @include xl {
        display: flex;
      }
    }
    @include xl {
      padding: 0;
      border: none;
      flex-direction: column;
      align-items: flex-start;
    }
    h3 {
      font-size: 1.4vw;
      font-weight: 400;
      position: relative;
      max-width: fit-content;
      line-height: 1.6vw;
      @include xl {
        font-size: 17px;
        line-height: 23px;
        color: var(--color-white);
      }
    }
  }

  .studio-faces__row {
    .btn-outline {
      position: static;
      transform: inherit;
    }
  }
  &__main {
    border-radius: clamp(16px, 1.1vw, 20px);
    padding: clamp(24px, 2.5vw, 48px);
    background: var(--color-gray-450);
    @include xl {
      border-radius: 16px;

      position: relative;
    }
  }
  &__boxes {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    grid-gap: clamp(40px, 10vw, 200px);
    @include xl {
      flex-direction: column;
      justify-content: flex-start;
      grid-gap: 40px;
    }
    h1 {
      font-size: clamp(32px, 3.5vw, 65px);
      line-height: 1.1;
      color: var(--color-dark);

      span {
        color: #ccc;
      }
    }
    p {
      color: var(--color-dark);
      font-size: clamp(15px, 1vw, 19px);
      max-width: 29vw;
      font-weight: 400;
      line-height: 1.4;
      @include xl {
        max-width: 100%;
      }
    }
  }
  &__column {
    display: grid;
    grid: auto / 1fr;
    grid-gap: clamp(15px, 1vw, 19px);
    @include md {
      grid: auto / repeat(2, 1fr);
      .peaches-studio__items {
        &:nth-child(2) {
          grid-gap: 12px;
          padding: 18px;
          align-items: flex-start;
          flex-direction: column;
        }
      }
    }
    @include xs {
      grid: auto / repeat(1, 1fr);
    }
  }
  &__info {
    display: grid;
    grid: auto / repeat(2, 1fr);
    grid-gap: 3vw;
    @include md {
      grid: auto / repeat(1, 1fr);
      grid-gap: 0;
    }
    &-text {
      font-size: clamp(15px, 1vw, 18px);
      font-weight: 400;
      text-transform: inherit;
      color: var(--color-dark);
      line-height: 1.4;
      margin-bottom: 1vw;
      @include xl {
        margin-bottom: 0;
      }
    }
    &-item {
      display: grid;
      grid: auto / 12vw 1fr;
      grid-gap: 1vw;
      padding-top: 1vw;
      margin-top: 3vw;

      border-top: 1px solid var(--border-dark);
      @include xl {
        grid: auto / 1fr;
        grid-gap: 24px;
        padding: 32px 24px;

        border: none;
        &:nth-child(even) {
          background: var(--color-gray-450);
          border-radius: 8px;

          padding: 24px;

          position: relative;
          p,
          .peaches-studio__info-text {
            color: var(--color-dark);
          }
        }

        .studio-stats__button .btn-outline {
          display: flex;
          align-items: center;
        }
        .studio-stats__button a.mobile {
          width: 38px;
          height: 38px;
        }
        .studio-stats__button img {
          width: 38px;
          height: 38px;
        }
      }
      @include md {
        margin: 0;
      }
      p {
        font-size: clamp(16px, 0.9vw, 19px);
        font-weight: 600;
        text-transform: inherit;
        color: var(--color-dark);
        line-height: 1.3;
        @include md {
          font-size: clamp(18px, 0.9vw, 19px);
        }
      }
    }
  }
  &__items {
    border-radius: 1vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 6vw;
    padding: 0 1.5vw;
    background: var(--color-dark-500);
    @include xl {
      border-radius: 12px;
      min-height: 70px;
      grid-gap: 12px;
      padding: 18px;

      &:nth-child(1) {
        grid-gap: 12px;
        padding: 18px;
        align-items: flex-start;
        flex-direction: column;
      }
    }
    &.i-box {
      height: 100%;
      display: flex;
      padding: 1.5vw;
      align-items: flex-start;
      flex-direction: column;
      justify-content: space-between;
      @include md {
        flex-direction: row;
        align-items: center;
        padding: 18px;
      }
    }
    p {
      font-size: 2.6vw;
      line-height: 3vw;
      font-weight: 400;
      font-family: "Nunito Sans", sans-serif;

      color: var(--color-white);
      letter-spacing: -0.1vw;
      @include xl {
        font-size: 40px;
        line-height: 40px;
      }
    }
    span {
      font-size: clamp(15px, 1vw, 19px);
      line-height: 1.2;
      color: var(--color-white);
    }
  }
  &__rows {
    display: grid;
    align-items: flex-end;
    grid: auto / 38vw 20vw 1fr;
    grid-gap: 1vw;
    @include xl {
      margin-top: 32px;
    }
    @include lg {
      grid: auto / 32vw 22vw 1fr;
    }
    @include md {
      grid-gap: 12px;
      margin-top: 24px;
      grid: auto / 1fr;
    }
  }
  &__page {
    min-height: 100vh;
    padding: clamp(70px, 8vw, 130px) 0 clamp(30px, 4vw, 76px) 0;
    @include xl {
      padding: clamp(70px, 9.5vw, 130px) 0 clamp(10px, 4vw, 76px) 0;
    }
  }
  .studio-faces__row {
    @include xl {
      grid-gap: 12px;
    }
  }

  &__box {
    display: flex;
    align-items: center;
    margin-bottom: clamp(32px, 3.5vw, 70px);
    justify-content: space-between;
    @include xl {
      flex-wrap: wrap;
      grid-gap: 22px;
    }
  }
  &__download {
    overflow: hidden;
    cursor: pointer;
    position: relative;
    display: flex;
    font-size: clamp(15px, 0.95vw, 18px);
    align-items: center;
    grid-gap: clamp(8px, 0.5vw, 9.5px);
    img {
      width: clamp(22px, 1.3vw, 25px);
      height: clamp(22px, 1.3vw, 25px);
      filter: var(--filter-invert);
    }
    &:hover span {
      transform: translateY(-110%);
    }
    span {
      display: flex;
      color: var(--color-dark);
      position: relative;
      transition: transform 1.2s cubic-bezier(0.19, 1, 0.22, 1);
      &::after {
        content: attr(data-label);
        position: absolute;
        top: 110%;
        left: 0;
        will-change: transform;
      }
    }
  }
}
