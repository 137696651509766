@use "~mixins" as *;
.studio-faces {
  padding: clamp(80px, 9vw, 160px) 0 clamp(30px, 4vw, 76px) 0;
  position: relative;

  &.peaches-faces {
    padding: 4vw 0 10vw 0;
    @include md {
      padding: 48px 0 110px 0;
    }
    .studio-faces__row {
      @include md {
        position: relative;
      }
    }
    .studio-faces__head {
      @include md {
        top: 40px;
        color: var(--color-dark);
      }
    }
  }

  &__box {
    opacity: 0;
    animation-duration: 1s;
    animation-delay: 1s;
    animation: count-fade 1s ease forwards;
    position: relative;
    img {
      background: var(--color-gray-500);
    }
  }
  &__link {
    position: absolute;
    bottom: 0;
    left: 0;
    // z-index: -1;
    width: 100%;
    height: 100%;
  }
  &__loader {
    width: 100%;

    position: absolute;
    left: 50%;
    top: 5vw;
    transform: translate(-50%, -50%);
    height: 100%;
  }
  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3vw;
    @include lg {
      margin-bottom: 24px;
      flex-wrap: wrap;
      grid-gap: 24px;
    }
  }
  &__head {
    color: var(--color-dark);

    @include lg {
      font-size: 32px;
      line-height: 38px;
    }
    @include md {
      font-size: 24px;
      line-height: 32px;
    }
  }
  &__delete {
    position: absolute;
    top: 1vw;
    left: 50%;
    transform: translate(-50%, 0);
    background: var(--color-black);

    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5vw;
    height: 2.5vw;
    transition: 0.4s ease;
    &:hover {
      transform: scale(1.1) translate(-50%, 0);
    }
    img {
      background: transparent;
      width: 1.2vw;
      height: 1.2vw;
      filter: brightness(0) invert(1) !important;
      object-fit: contain;
    }
  }
  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8vw;
    white-space: nowrap;
    text-align: center;
    padding: 0.4vw 1.2vw;
    border-radius: 0.5vw;
    color: var(--color-black);
    cursor: pointer;
    background: var(--color-white);
    transition: 0.4s ease;
    &:hover {
      transform: scale(1.05);
    }
  }
  &__control {
    display: flex;
    align-items: center;
    grid-gap: 0.5vw;
    flex-wrap: wrap;
    position: absolute;
    // background: var(--color-white);
    padding: 0.5vw;
    border-radius: 0.6vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    img {
      width: 1.6vw;
      height: 1.6vw;
      cursor: pointer;
      transition: 0.4s ease;
      &:hover {
        transform: scale(1.04);
      }
    }
  }
  &__row {
    display: grid;
    grid: auto / repeat(5, 1fr);
    grid-gap: clamp(16px, 1vw, 19px);
    overflow: hidden;
    @include xl {
      grid: auto / repeat(4, 1fr);
    }
    @include lg {
      grid: auto / repeat(3, 1fr);
    }
    @include md {
      grid: auto / repeat(2, 1fr);
    }

    .btn-outline {
      position: absolute;
      left: 50%;
      bottom: 4vw;
      transform: translate(-50%, 0);
      @include md {
        bottom: -90px;
      }
    }
  }

  &__section {
    padding: 8vw 0;
    @include md {
      padding: 100px 0 80px 0;
    }
  }
  &__content {
    .btn-outline {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      a {
        font-size: 0.85vw;
        padding: 0 1.5vw;
        text-transform: inherit;
        @include md {
          line-height: 34px;
          padding: 0 20px;
          font-size: 15px;
        }
      }
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 1.5vw;
    left: 0;
    @include lg {
      padding: 16px;
    }
    svg {
      width: 2vw;
      height: 2vw;
      @include lg {
        width: 22px;
        height: 22px;
      }
    }
    .studio-faces__title {
      position: static;
    }
  }

  &__item {
    position: relative;
    height: 100%;
    width: 100%;
    display: block;
    clip-path: inset(0vw round 1vw);
    transition:
      1.2s cubic-bezier(0.16, 1.08, 0.38, 0.98),
      -webkit-transform 1.4s cubic-bezier(0.16, 1.08, 0.38, 0.98);
    @include lg {
      border-radius: 12px;
      overflow: hidden;
    }
    &:hover {
      clip-path: inset(0.5vw round 1vw);
      @include md {
        clip-path: inset(0vw round 18px);
      }
      img {
        filter: none;
      }
      .studio-faces__title {
        span {
          transform: translateY(-110%);
        }
      }
    }

    @for $i from 1 through 40 {
      &:nth-child(#{$i}n) .studio-faces__figure {
        transition-delay: #{$i * 0.05}s;
      }
    }
  }

  &__title {
    position: absolute;
    bottom: clamp(24px, 2vw, 36px);
    left: clamp(24px, 2vw, 36px);
    font-size: clamp(24px, 1.2vw, 23px);
    color: var(--color-white);
    overflow: hidden;
    &:hover {
      border-color: var(--color-black-800);
    }
    span {
      display: flex;
      align-items: center;
      grid-gap: 0.3vw;
      position: relative;
      transition: transform 1.2s cubic-bezier(0.19, 1, 0.22, 1);
      &::after {
        content: attr(data-label);
        position: absolute;
        top: 110%;
        left: 0;
        will-change: transform;
      }
    }

    @include sm {
      left: 16px;
      bottom: 16px;
      font-size: 18px;
    }
  }
  &__figure {
    position: relative;
    width: 100%;
    display: block;
    // opacity: 0;
    border-radius: 1vw;
    clip-path: inset(0 round 1vw);
    position: relative;
    transition:
      transform 1.1s cubic-bezier(0.165, 0.84, 0.44, 1),
      opacity 0.3s;

    @include md {
      border-radius: 14px;
    }
    &:hover {
      @include md {
        clip-path: inherit;
      }
    }
    &::before {
      content: "";
      display: block;
      padding-bottom: 117%;
      @include sm {
        padding-bottom: 120%;
      }
    }

    video {
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      transition: inherit;
      transform: scale(1.2);
      filter: brightness(0.9);
      @include md {
        border-radius: 14px;
      }
    }
    img {
      width: 100%;
      border-radius: 1vw;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      transition: inherit;
      transition: var(--smooth-transition);
      filter: brightness(0.9);
      transform: scale(1.2);
      &.filter-opacity {
        filter: brightness(0.4);
      }
      @include md {
        transform: scale(1.1);
        border-radius: 14px;
      }
    }
  }
}
