.nav-header.nav-header input {
  &::before {
    width: clamp(14px, 1.1vw, 20px);
    height: clamp(14px, 1.1vw, 20px);
    left: clamp(2px, 0.2vw, 3.8px);
    top: 50%;
    transform: translate(0, -50%);
    background: var(--color-white) url("https://platform.peaches.studio/assets/images/icon/dark.svg") center no-repeat;
    background-size: contain;
    background-size: clamp(10px, 0.7vw, 14px) clamp(10px, 0.7vw, 14px);
  }
}
.nav-header.nav-header input:checked {
  background: transparent;
}
.nav-header.nav-header input:checked:hover,
.nav-header.nav-header input:checked:hover::after {
  background-color: transparent;
}
.nav-header.nav-header input:checked::before {
  left: clamp(calc(35px - 18px), calc(3vw - 1.1vw - 4px), calc(57px - 26px));

  background: var(--color-dark) url("https://platform.peaches.studio/assets/images/icon/light.svg") center no-repeat;
  background-size: contain;
  background-size: clamp(10px, 0.7vw, 13.3px) clamp(10px, 0.7vw, 13.3px);
}
.nav-header.nav-header input::after {
  display: none;
}
.nav-header.nav-header input {
  height: clamp(20px, 1.6vw, 30px);
  width: clamp(35px, 3vw, 57px);
  border-radius: clamp(100px, 2vw, 36px);
  background: transparent;
  border: 1px solid var(--color-dark);
  @include md {
    border-width: 0.5px;
  }
  &:hover {
    background: transparent;
  }
}
