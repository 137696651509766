.slider-bloggers {
  width: 100%;
  // overflow: hidden;
  display: flex;
  align-items: center;
  padding: 0 1vw;
  &__inboxes {
    opacity: 0;
    animation-duration: 0.1s;
    animation-delay: 0.4s;
    animation: count-fade 0.5s ease forwards;
  }
  &__section {
    background-color: var(--color-black);
    border-radius: 1vw;

    width: 100%;
    overflow: hidden;

    &.progress {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    @include lg {
      border-radius: 14px;
    }
    // @include lg {
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    // }
  }
  .swiper-wrapper {
    align-items: center;
  }
  &__nav {
    display: flex;
    align-items: center;
    grid-gap: 0.5vw;
    margin-right: 1vw;
    @include lg {
      margin-right: 18px;
      grid-gap: 12px;
    }
    @include sm {
      grid-gap: 8px;
      margin-right: 20px;
    }
    &-prev {
      width: 1.7vw;
      height: 1.7vw;
      flex: 0 0 1.7vw;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: 1px solid var(--color-white);
      cursor: pointer;
      transition: 0.4s ease;
      @include lg {
        width: 32px;
        height: 32px;
        flex: 0 0 32px;
      }
      &:hover {
        transform: scale(1.05);
      }
      svg {
        width: 0.8vw;
        height: 0.8vw;
        display: block;
        fill: var(--color-white);
        @include lg {
          width: 20px;
          height: 20px;
        }
        @include sm {
          width: 16px;
          height: 16px;
        }
      }
    }
    &-next {
      width: 1.7vw;
      height: 1.7vw;
      flex: 0 0 1.7vw;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      transform: rotate(180deg);
      border: 1px solid var(--color-white);
      cursor: pointer;
      transition: 0.4s ease;
      @include lg {
        width: 32px;
        height: 32px;
        flex: 0 0 32px;
      }
      &:hover {
        transform: scale(1.05) rotate(180deg);
      }
      svg {
        width: 0.8vw;
        height: 0.8vw;
        display: block;
        fill: var(--color-white);
        @include lg {
          width: 20px;
          height: 20px;
        }
        @include sm {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.4vw 0;
    @include lg {
      padding: 12px 0 0 0;
    }
  }
  &__title {
    color: rgba(255, 255, 255, 0.3);
    font-size: 0.9vw;
    padding-left: 1vw;
    display: block;
    @include lg {
      font-size: 16px;
      padding-left: 20px;
    }
  }
  .swiper-slide {
    width: 5.7vw;
    margin-right: 1vw;
    height: 5.7vw;
    // overflow: hidden;
    @include lg {
      width: 110px;
      height: 110px;
      margin-right: 8px;
    }
    @include sm {
      width: 90px;
      height: 90px;
    }
    &:hover img {
      transform: scale(1.1);
      filter: opacity(0.3);
      @include lg {
        filter: opacity(1);
        transform: scale(1);
      }
    }
    &:hover span {
      opacity: 1;
    }
    &:last-child {
      margin-right: 0;
    }

    a {
      border-radius: 50%;
      height: 5.7vw;
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      position: relative;
      @include lg {
        width: 110px;
        height: 110px;
      }
      @include sm {
        width: 90px;
        height: 90px;
      }
    }

    span {
      position: absolute;
      transition: 0.4s ease;
      top: 50%;
      left: 50%;
      z-index: -1;
      transform: translate(-50%, -50%);
      white-space: nowrap;
      font-size: 0.65vw;
      opacity: 0;
      margin-top: 0.2vw;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 600;
      color: var(--color-white);
      @include lg {
        font-size: 12px;
        position: static;
        opacity: 1;
        margin-top: 10px;
        display: block;
        transform: translate(0, 0);
      }
      @include sm {
        font-size: 11px;
      }
    }
    img {
      object-fit: contain;
      transition: 0.4s ease;
      width: 100%;
    }
  }
  @include lg {
    border-radius: 14px;
    padding: 16px 18px 40px;
  }
}
