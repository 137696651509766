@use "~mixins" as *;
.nav-header {
  padding: clamp(16px, 1vw, 19px) 0;
  width: 100%;
  position: fixed;
  transition: 0.8s cubic-bezier(0.38, 0.005, 0.215, 1);
  display: block;
  will-change: transform;
  z-index: 99999;
  top: 0;
  &.hide {
    opacity: 0;
    visibility: hidden;
  }
  &__main {
    background: var(--color-gray-450);
    padding: 0 clamp(16px, 2vw, 32px);
    @include lg {
      padding: 0;
      background: transparent;
    }
  }
  &__wrap {
    display: flex;
    align-items: center;
    grid-gap: 3vw;
    @include lg {
      grid-gap: 24px;
      .studio-stats__button.dark {
        @include lg {
          display: none;
        }
      }
      .studio-stats__button.dark .btn-outline button,
      a {
        color: var(--color-dark) !important;
        border-color: var(--color-white) !important;
        background: var(--color-white) !important;
      }
    }
  }
  &__hamburger {
    display: none;
    @include lg {
      display: block;
      span {
        width: 18px;
        height: 20px;
        top: 5px;
        border-radius: 20px;
        display: block;
        position: relative;
        &::after {
          content: "";
          transition: 0.6s cubic-bezier(0.38, 0.005, 0.215, 1);
          display: block;
          position: absolute;
          left: 50%;
          height: 1.4px;
          border-radius: 40px;
          width: 100%;
          top: 40%;
          transform: translate(-50%, -50%);
          background: var(--color-dark);
          transition: 0.4s ease;
          @include lg {
            background: var(--color-dark);
          }
        }
        &::before {
          content: "";
          display: block;
          position: absolute;
          transition: 0.6s cubic-bezier(0.38, 0.005, 0.215, 1);
          left: 50%;
          height: 1.4px;
          top: 0;
          width: 100%;
          border-radius: 40px;
          transform: translate(-50%, -50%);
          background: var(--color-dark);
          transition: 0.4s ease;
          @include lg {
            background: var(--color-dark);
          }
        }
      }
    }
    &.active {
      span::before {
        background: white;
      }
      span::after {
        background: white;
      }
    }
  }
  &__main {
    display: flex;
    align-items: center;
    padding: clamp(13px, 0.7vw, 14px) clamp(13px, 0.7vw, 14px) clamp(13px, 0.7vw, 14px) clamp(20px, 1.5vw, 30px);
    position: relative;
    border-radius: clamp(10px, 0.7vw, 13px);
    justify-content: space-between;
    @include lg {
      padding: 0;
      border: none;
      background: transparent;
      border-radius: 0;
    }
  }

  &__list {
    position: relative;
    left: -2.5vw;
    @include lg {
      display: none;
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      li {
        margin-right: 2.5vw;
        overflow: hidden;
        display: flex;
        align-items: center;
        @include lg {
          margin-right: 24px;
        }

        a {
          display: flex;
          align-items: center;
          font-size: var(--font-sm);
          color: var(--color-dark);
          line-height: var(--line-h-sm);
          text-decoration: none;
          overflow: hidden;
          position: relative;
          font-weight: 400;
          grid-gap: 0.3vw;
          &::before {
            content: "";
            display: block;
            width: 0.15vw;
            height: 0.15vw;
            opacity: 0;
            transition: var(--fast-transition);
            visibility: hidden;
            border-radius: 50%;
          }
          &:hover span {
            transform: translateY(-110%);
          }
          span {
            display: flex;
            position: relative;
            transition: transform 1.2s cubic-bezier(0.19, 1, 0.22, 1);
            &::after {
              content: attr(data-label);
              position: absolute;
              top: 110%;
              left: 0;
              will-change: transform;
            }
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  &__boxes {
    display: flex;
    align-items: center;
    grid-gap: 20vw;
    @include lg {
      display: block;
    }
  }

  &__block {
    display: flex;
    grid-gap: 2vw;
    align-items: center;
  }
  &__text {
    font-weight: 400;
    font-size: 9vw;
    padding-right: 2vw;
    color: var(--color-black);
  }
  &__logo {
    @include lg {
      position: static;
      transform: none;
    }
    img {
      width: clamp(170px, 9vw, 180px);
      filter: var(--filter-invert);
      transition: 0.4s ease;

      @include lg {
        width: 150px;
      }
    }
  }
  &__link {
    font-size: 0.92vw;

    text-decoration: none;
    color: var(--color-black);
  }
  &__year {
    font-size: var(--font-sm);
    color: var(--color-black);
    bottom: 0;
    z-index: 99;
    font-weight: 400;
  }
  &__inner {
    position: absolute;
    right: 0;
    padding: 2vw 3vw;
    display: flex;
    width: 100%;
    height: auto;
    z-index: -2;
    top: calc(100vh - 5vw);

    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @include lg {
      bottom: 0;
      padding: 16px;
      top: auto;
      display: none;
    }
  }
  &__progress {
    font-size: var(--font-sm);
    color: var(--color-black);
    bottom: 0;
    z-index: 99;
    font-weight: 400;
    text-transform: uppercase;
  }
}
