@use "~mixins" as *;
.studio-contact {
  overflow: hidden;
  width: 100%;
  height: calc(var(--vh) * 100);
  @include md {
    overflow: auto;
  }
  &__content {
    min-height: 700px;
    height: calc(100vh - 10vw);
    margin-top: 7vw;
    border-radius: 1.5vw;
    background: var(--color-gray-450);
    // background: var(--color-gray-450);
    display: flex;
    position: relative;

    align-items: center;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    @include md {
      flex-grow: 1;
      min-height: auto;
      border-radius: 12px;
      position: relative;
      padding: 32px;
      height: calc(100vh - 100px);
      margin: 80px 0 40px 0;
    }
  }
  &__icon {
    border: 1px solid var(--color-black-900);
    border-radius: 50%;
    width: 2vw;
    height: 2vw;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 0.7vw;
      height: 0.7vw;
    }
  }
  &__boxes {
    font-size: 0.86vw;
    color: var(--color-black-800);
    line-height: 1.7vw;
    display: flex;
    align-items: center;
    grid-gap: 1vw;
    position: absolute;
    bottom: 1.5vw;
  }
  &__button {
    display: flex;
    justify-content: center;
    grid-gap: 0;
    align-items: center;
    img {
      width: clamp(32px, 2.2vw, 36px);
      height: clamp(32px, 2.2vw, 36px);
    }
    a {
      font-size: clamp(14px, 0.8vw, 16px);
      line-height: clamp(34px, 2.4vw, 40px);
      padding: 0 clamp(16px, 2vw, 32px);
      border-radius: clamp(8px, 0.5vw, 10px);
      text-transform: inherit;

      @include md {
        border-width: 1px;
        border-radius: 12px;
      }
    }

    img {
      width: clamp(34px, 2.4vw, 42px);
      height: clamp(34px, 2.4vw, 42px);
      transition: 0.4s ease;

      filter: var(--filter-invert);
    }
  }
  &__title {
    color: var(--color-dark);
    font-size: 2.4vw;
    max-width: 40%;
    font-weight: 400;
    line-height: 3vw;
    margin-bottom: 3.5vw;
    @include md {
      font-size: 26px;
      max-width: 100%;
      line-height: 35px;
      margin-bottom: 35px;
    }
  }
  &__subtitle {
    font-size: clamp(16px, 0.9vw, 18px);
    line-height: 1.3;
    margin-bottom: clamp(8px, 1.5vw, 30px);
    color: var(--color-dark);
    border-radius: 10px;
    padding: 0 clamp(24px, 1.5vw, 30px);
  }
  &__item {
    display: flex;
    flex-direction: column;
    grid-gap: 0.2vw;
    border-radius: 1.25vw;
    @include md {
      border-radius: 14px;
      padding: 12px;
      grid-gap: 4px;
    }
  }

  &__info {
    display: flex;
    align-items: center;
    grid-gap: 0.8vw;
  }
  &__block {
    display: grid;
    grid: auto / repeat(3, 1fr);
    grid-gap: 1vw;
    @include md {
      grid: auto / repeat(1, 1fr);
      grid-gap: 16px;
    }
    .btn-outline a {
      // padding: 0 1vw;
      font-size: clamp(14px, 0.88vw, 17px);
      border-radius: clamp(8px, 0.5vw, 10px);
      text-transform: inherit;
      color: var(--color-dark);
      border-color: var(--color-dark-900);
      // line-height: 1.9vw;
      // &:hover {
      //   border-color: var(--color-white);
      // }
      @include md {
        border-width: 1px;
        line-height: 36px;
        border-radius: 12px;
        padding: 0 24px;
        font-size: 15px;
      }
    }
    @include md {
      margin-top: 16px;
      grid-gap: 16px;
      align-items: flex-end;
    }
    // @include sm {
    //   flex-direction: column;
    // }
  }
  &__subhead {
    font-size: 0.82vw;
    color: var(--color-black-800);
    line-height: 1.7vw;
    @include md {
      font-size: 15px;
      margin-bottom: 6px;
      line-height: 22px;
    }
  }
}
