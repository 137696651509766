.peaches-error {
  &__content {
    min-height: 100vh;
    min-height: 100svh;
    display: grid;
    place-items: center;
    grid: auto / 1fr 40vw;
    grid-gap: clamp(70px, 10vw, 190px);
    @include md {
      padding-top: 120px;
      display: flex;
      flex-direction: column;
    }
  }
  &__img {
    width: 100%;
    display: block;
    @include lg {
      order: 1;
    }
    svg {
      width: 100%;
      height: 100%;
    }
  }
  &__title {
    span {
      border-radius: clamp(6px, 0.6vw, 12px);
      padding: clamp(4px, 0.3vw, 6px) clamp(12px, 1vw, 19px);
      margin: 0 clamp(4px, 0.3vw, 6px);
      font-size: clamp(24px, 1.7vw, 32px);
      color: var(--color-dark);
      background: var(--color-orange);
    }
  }
  &__main {
    width: 100%;
    @include lg {
      order: 2;
    }
  }
  &__text {
    font-size: clamp(15px, 1vw, 19px);
    margin: clamp(30px, 2vw, 36px) 0 clamp(40px, 3.5vw, 67px) 0;
    font-weight: 400;
    line-height: 1.4;
    color: var(--color-dark);

    @include lg {
      border-radius: 14px;
    }
  }
  // .peaches-error__button.peaches-error__button {
  //   button {
  //     font-size: clamp(14px, 0.8vw, 16px);
  //     padding: 0 clamp(24px, 3vw, 57px);
  //     line-height: clamp(34px, 2.4vw, 40px);;
  //   }
  //   img {
  //     width: clamp(34px, 2.4vw, 40px);;
  //     height: clamp(34px, 2.4vw, 40px);;
  //   }
  // }
}
