@use "~mixins" as *;

.nav-mobile {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  height: 100svh;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  background: var(--color-dark-800);
  z-index: 1300;
  clip-path: inset(0 0 100% 0);
  transition:
    clip-path 0.8s cubic-bezier(0.38, 0.005, 0.215, 1),
    -webkit-clip-path 0.8s cubic-bezier(0.38, 0.005, 0.215, 1);

  will-change: transform;
  &__text {
    color: var(--color-gray-300);
    font-size: var(--font-sm);
    line-height: var(--line-h-sm);
  }
  &__box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    opacity: 0;
    padding: 32px 25px 30px;
    position: relative;
    transition: all 1.2s cubic-bezier(0.7, 0, 0.2, 1);
    will-change: transform;
    @include lg {
      .studio-stats__button .btn-outline a,
      .studio-stats__button .btn-outline button {
        line-height: 38px;
        padding: 0 28px;
        background: var(--color-white) !important;
        color: var(--color-dark-800) !important;
      }
    }
    &.hide {
      transition: all 0.9s cubic-bezier(0.7, 0, 0.2, 1) !important;
      opacity: 0 !important;
    }
  }
  &__social {
    ul {
      padding: 0;
      margin: 0;

      justify-content: space-between;
      display: flex;
      width: 100%;
      align-items: center;
    }
    @include md {
      grid-gap: 24px;
    }
    li {
      overflow: hidden;
      display: inline-block;
      height: 1.6vw;

      width: 33.33%;
      margin-right: 2vw;
      line-height: var(--line-h-sm);
      @for $i from 1 through 20 {
        &:nth-child(#{$i}n) a,
        span {
          transition-delay: #{$i * 0.2}s;
        }
      }
      &:nth-child(2) {
        span {
          justify-content: center;
        }
      }
      &:nth-child(3) {
        text-align: right;
      }
      @include md {
        height: inherit;
        margin-right: 0;
      }
      &:hover span {
        transform: translateY(-110%);
        color: var(--color-white);
      }
      span {
        color: var(--color-gray-300);
        display: flex;
        position: relative;
        transition: transform 1.2s cubic-bezier(0.19, 1, 0.22, 1);
        &::after {
          content: attr(data-label);
          position: absolute;
          top: 110%;
          left: 0;
          will-change: transform;
        }
      }
      &:last-child {
        margin-right: 0;
      }
      a {
        font-size: var(--font-sm);
        line-height: var(--line-h-sm);
        text-decoration: none;
        // transform: translateY(30px);
        transition: all 1.2s cubic-bezier(0.7, 0, 0.2, 1);
        // opacity: 0;
        display: block;
        text-align: center;
        position: relative;
        overflow: hidden;
        color: var(--color-white);
      }
    }
  }
  &__list {
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      li {
        margin-bottom: 30px;
        overflow: hidden;
        @for $i from 1 through 6 {
          &:nth-child(#{$i}n) a {
            transition-delay: #{$i * 0.15}s;
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
        a {
          transition:
            1s cubic-bezier(0.16, 1.08, 0.38, 0.98),
            -webkit-transform 1s cubic-bezier(0.16, 1.08, 0.38, 0.98);
          // opacity: 0;
          display: block;
          will-change: transform;
          color: var(--color-white);
          font-size: 32px;
          line-height: 40px;
        }
      }
    }
  }
  @include md {
    display: block;
  }
}

.nav-active .nav-mobile__list ul li a {
  transform: none;
  opacity: 1;
}
.nav-active .nav-mobile__box {
  transform: translate(0, 0) rotate(0.001deg);
  transition-delay: 0.6s;
  opacity: 1;
}
.nav-active .nav-mobile__social ul li a {
  transform: none;
  opacity: 1;
}

.nav-active .nav-mobile {
  clip-path: inset(0 0 0 0);
}
main .nav-header__logo svg path {
  transition: all 0.5s cubic-bezier(0.7, 0, 0.2, 1);
}

main.nav-active .nav-header__logo svg path {
  transition-delay: 0.05s;
  fill: var(--color-white);
}
main.nav-active .nav-header__hamburger span::before {
  background: var(--color-white);
}

main.nav-active .nav-header__hamburger span::after {
  background: var(--color-white);
}
.nav-header__hamburger.active span::before {
  top: 20%;
  transform: translate(-50%, -50%) rotate(-45deg);
}
.nav-header__hamburger.active span::after {
  top: 20%;
  transform: translate(-50%, -50%) rotate(45deg);
}
