@use "~mixins" as *;

.peaches-calendar {
  padding: clamp(70px, 8vw, 130px) 0 clamp(30px, 4vw, 76px) 0;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  @include xl {
    padding: clamp(70px, 9.5vw, 130px) 0 clamp(30px, 4vw, 76px) 0;
  }
  .container {
    display: flex;
    height: 100%;
    min-height: calc(100vh - 7vw);
    flex-direction: column;
    @include lg {
      min-height: auto;
    }
  }
  &__theme {
    color: rgba(255, 255, 255, 0.3);
    font-size: 0.8vw;
    font-weight: 400;
    margin-bottom: 0.5vw;
    @include md {
      font-size: 14px;
      margin-bottom: 4px;
    }
  }

  &__select {
    &-month {
      color: #494949;
    }
  }
  .peaches-calendar__select.peaches-calendar__select {
    width: 100%;
    color: white;
    font-size: 1vw;
    font-family: "Nunito Sans", sans-serif;
    font-weight: 400;
    margin-bottom: 2vw;
    @include md {
      margin-bottom: 24px;
      font-size: 18px;
    }
    // &.Mui-focused {
    //   &::after {
    //     background: red;
    //   }
    // }
    // svg {
    //   display: none !important;
    // }
    svg {
      opacity: 0 !important;
      + fieldset::after {
        content: "";
        border: solid white;
        border-width: 0 1.5px 1.5px 0;
        display: inline-block;
        padding: 0.17vw;
        right: 0.2vw;
        top: -0.25vw;
        width: 0.15vw;
        height: 0.15vw;
        position: absolute;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        vertical-align: middle;
        transition: 0.4s ease;
        @include md {
          padding: 1.5px;
          right: 2px;
          top: -3px;
          width: 4px;
          height: 4px;
        }
      }
      &.MuiSelect-iconOpen {
        + fieldset::after {
          transform: rotate(-135deg);
        }
      }
    }
    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 !important;
    }
    fieldset {
      padding: 0;
      overflow: inherit !important;
      border: none !important;
    }
  }
  &__events {
    position: relative;
  }
  &__evpoint {
    position: absolute;
    z-index: 10;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 0.38vw;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 0.2vw;
    @include lg {
      bottom: 3px;
      grid-gap: 2px;
    }
    &.view-3 {
      width: 3vw;
      z-index: -1;
      + button.Mui-selected {
        z-index: 1;
      }
      &::after {
        content: "";
        z-index: -1;
        position: absolute;
        width: 2.8vw;
        height: 2.8vw;
        top: -2.3vw;
        left: 0.1vw;
        flex: 0 0 2.8vw;
        background: url("https://platform.peaches.studio/assets/images/icon/view-3.svg") center no-repeat;
        background-size: contain;
        opacity: 0;
      }
    }
  }
  &__point {
    width: 0.3vw;
    flex: 0 0 0.3vw;
    height: 0.3vw;
    border-radius: 50%;
    @include lg {
      width: 3px;
      height: 3px;
      flex: 0 0 3px;
    }
    &--blue {
      background: var(--color-blue-100);
    }
    &--green {
      background: var(--color-green-100);
    }
    &--orange {
      background: var(--color-orange-100);
    }
    &--violet {
      background: var(--color-violet-100);
    }
  }

  &__choose {
    &-wrap {
      display: flex;
      position: absolute;
      left: 13.5vw;
      bottom: 0;
      align-items: center;
      grid-gap: 1vw;
      @include lg {
        position: static;
        grid-gap: 16px;
      }
      svg {
        width: 1.5vw;
        height: 1.5vw;
        @include lg {
          width: 32px;
          height: 32px;
        }
      }
    }
    &-variant {
      display: flex;
      align-items: center;
      grid-gap: 0.7vw;
      @include lg {
        grid-gap: 10px;
      }
      span {
        width: 0.6vw;
        flex: 0 0 0.6vw;
        border-radius: 50%;
        height: 0.6vw;
        display: block;
        @include lg {
          width: 10px;
          height: 10px;
          flex: 0 0 10px;
        }
        &:nth-child(1) {
          background-color: var(--color-green-100);
        }
        &:nth-child(2) {
          background-color: var(--color-blue-100);
        }
        &:nth-child(3) {
          background-color: var(--color-orange-100);
        }
      }
    }
    h1 {
      font-size: 1.6vw;
      font-weight: 400;
      line-height: 2.2vw;
      margin-bottom: 2vw;
      color: var(--color-white);
      @include lg {
        font-size: 25px;
        margin-bottom: 24px;
        line-height: 35px;
      }
    }
    h2 {
      color: var(--color-white);
      font-size: 0.95vw;
      position: relative;
      text-transform: inherit;
      font-weight: 400;
      // padding-right: 12vw;
      padding-right: 5vw;
      margin-bottom: 2vw;
      line-height: 1.6vw;
      @include lg {
        font-size: 15px;
        margin-bottom: 24px;
        line-height: 24px;
        padding-right: 0;
      }
    }
  }
  &__main {
    flex-grow: 1;
    display: flex;

    height: 100%;

    flex-direction: column;
    .MuiCircularProgress-svg {
      color: var(--color-dark) !important;
    }
    @include lg {
      border-radius: 12px;
      min-height: 80vh;
      .MuiCircularProgress-indeterminate {
        width: 30px !important;
        height: 30px !important;
      }
    }
    &.bg-gray {
      justify-content: center;
      align-items: center;
      color: var(--color-dark);
      font-size: 1.3vw;
      line-height: 1.8vw;
      border-radius: clamp(16px, 1.1vw, 20px);
      padding: clamp(24px, 2.5vw, 48px);
      @include lg {
        padding: 36px;
        border-radius: 14px;
      }
    }
  }
  @include lg {
    flex-grow: 1;
    min-height: auto;
  }

  &__overlays {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.1);
    -webkit-backdrop-filter: blur(5px);
    -moz-backdrop-filter: blur(5px);
    -ms-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    font-size: 1vw;
    color: var(--color-white);
    font-weight: 400;
    flex-direction: column;
    line-height: 1.3vw;
    z-index: 9999;
    @include lg {
      font-size: 15px;
      line-height: 24px;
    }
    p {
      max-width: 20vw;
      text-wrap: balance;
      text-align: center;
      margin-top: 2vw;
      @include lg {
        margin-top: 24px;
        max-width: 100%;
      }
    }
    svg {
      width: 3vw;
      height: 3vw;
      fill: var(--color-white);
      @include lg {
        width: 42px;
        height: 42px;
      }
    }
  }
  &__item {
    position: relative;
    flex: 0 0 33vw;
    max-width: 33vw;
    overflow: hidden;
    background-color: var(--color-black);
    border-radius: 1vw;
    padding: 2vw 2vw 2vw 2vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    opacity: 0;
    animation-duration: 0.1s;
    animation-delay: 2s;
    animation: count-fade 0.5s ease forwards;
    @include lg {
      order: 1;
      padding: 18px;
      flex: 1;
      max-width: 100%;
      border-radius: 14px;
    }
    .css-k9reix {
      color: var(--color-white);
    }
    .css-1bqdsf2.Mui-selected {
      background: var(--color-dark-700) !important;
    }
    .css-1bqdsf2.Mui-selected {
      background: var(--color-dark-700) !important;
    }
    .css-1bqdsf2 {
      width: 100%;
      height: 100%;
      border-radius: 100px;
      font-size: 0.8vw;
      margin: 0.4vw 0;
    }
    .css-nezcv-MuiButtonBase-root-MuiPickersDay-root {
      background: var(--color-dark-700) !important;
      color: var(--color-white);
      font-size: 0.85vw;
      font-family: "Nunito Sans", sans-serif;
      width: 3vw;
      height: 3vw;
      flex: 0 0 3vw;
      @include lg {
        width: 40px;
        height: 40px;
        flex: 0 0 40px;
        font-size: 14px;
      }
      @include sm {
        font-size: 12px;
        width: 32px;
        height: 32px;
        flex: 0 0 32px;
      }
    }
    .css-n3sqmy {
      background: var(--color-dark-700) !important;
      color: var(--color-white);
      font-size: 0.85vw;
      font-family: "Nunito Sans", sans-serif;
      width: 3vw;
      height: 3vw;
      flex: 0 0 3vw;
      @include lg {
        width: 40px;
        height: 40px;
        flex: 0 0 40px;
        font-size: 14px;
      }
      @include sm {
        font-size: 12px;
        width: 32px;
        height: 32px;
        flex: 0 0 32px;
      }
    }
    .css-1bvpx9d {
      width: 100%;
      height: 100%;
      max-height: 20vw;
    }
    .css-1vooibu-MuiSvgIcon-root {
      width: 1.1vw;
      height: 1.1vw;
      @include lg {
        width: 20px;
        height: 20px;
      }
      @include sm {
        width: 16px;
        height: 16px;
      }
    }
    .css-1cw4hi4 {
      width: 1.1vw;
      height: 1.1vw;
      @include lg {
        width: 20px;
        height: 20px;
      }
      @include sm {
        width: 16px;
        height: 16px;
      }
    }
    .css-5m299p-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
      background-color: transparent !important;
    }
    .css-xl8mvw.Mui-selected {
      background-color: transparent !important;
    }
    .css-nk89i7-MuiPickersCalendarHeader-root {
      padding-left: 0.8vw;
      padding-right: 0;
      margin: 0.4vw 0.5vw 1vw 0.5vw;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include lg {
        margin: 12px 0;
        padding: 0 0 0 16px;
      }
    }
    .css-1dozdou {
      padding-left: 0.8vw;
      padding-right: 0;
      margin: 0.4vw 0.5vw 1vw 0.5vw;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include lg {
        margin: 12px 0;
        padding: 0 0 0 16px;
      }
    }
    .css-1c32n2y-MuiBadge-root {
      width: 3vw;
      height: 3vw;
      flex: 0 0 3vw;
      @include lg {
        width: 40px;
        height: 40px;
        flex: 0 0 40px;
      }
      @include sm {
        width: 32px;
        height: 32px;
        flex: 0 0 32px;
      }
    }
    .css-dplwbx-MuiPickersCalendarHeader-label {
      white-space: nowrap;
    }
    .css-xb7uwb-MuiPickersArrowSwitcher-spacer {
      width: 1.3vw;
      @include lg {
        width: 24px;
      }
      @include sm {
        width: 20px;
      }
    }
    .css-qa7bje-MuiButtonBase-root-MuiPickersDay-root {
      transition: 0.4s ease;
      &:hover {
        background-color: var(--color-dark-700) !important;
      }
    }
    .css-1hrukl9 {
      transition: 0.4s ease;
      &:hover {
        background-color: var(--color-dark-700) !important;
      }
    }
    .css-qa7bje-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
      background-color: var(--color-dark-700) !important;
    }
    .css-1hrukl9.Mui-selected {
      background-color: var(--color-dark-700) !important;
    }
    .css-qa7bje-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover {
      background-color: var(--color-dark-700) !important;
    }
    .css-1hrukl9.Mui-selected:hover {
      background-color: var(--color-dark-700) !important;
    }
    .css-qa7bje-MuiButtonBase-root-MuiPickersDay-root {
      color: var(--color-white);
      font-size: 0.85vw;
      font-family: "Nunito Sans", sans-serif;
      width: 3vw;
      height: 3vw;
      flex: 0 0 3vw;
      @include lg {
        width: 40px;
        height: 40px;
        flex: 0 0 40px;
        font-size: 14px;
      }
      @include sm {
        font-size: 12px;
        width: 32px;
        height: 32px;
        flex: 0 0 32px;
      }
    }
    .css-1hrukl9 {
      color: var(--color-white);
      font-size: 0.85vw;
      font-family: "Nunito Sans", sans-serif;
      width: 3vw;
      height: 3vw;
      flex: 0 0 3vw;
      @include lg {
        width: 40px;
        height: 40px;
        flex: 0 0 40px;
        font-size: 14px;
      }
      @include sm {
        font-size: 12px;
        width: 32px;
        height: 32px;
        flex: 0 0 32px;
      }
    }

    .css-qa7bje-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
      background-color: var(--color-dark-700) !important;
    }
    .css-1hrukl9.Mui-selected {
      background-color: var(--color-dark-700) !important;
    }

    .css-cyfsxc-MuiPickersCalendarHeader-labelContainer {
      color: var(--color-white);
      font-size: 1.3vw;
      font-family: "Nunito Sans", sans-serif;

      font-weight: 400;
      line-height: 2vw;
      @include lg {
        font-size: 20px;
        line-height: 26px;
      }
      @include sm {
        font-size: 18px;
      }
    }
    .css-16j77m4 {
      color: var(--color-white);
      font-size: 1.3vw;
      font-family: "Nunito Sans", sans-serif;

      font-weight: 400;
      line-height: 2vw;
      @include lg {
        font-size: 20px;
        line-height: 26px;
      }
      @include sm {
        font-size: 18px;
      }
    }
    .css-5m299p-MuiButtonBase-root-MuiPickersDay-root {
      color: rgba(255, 255, 255, 0.2) !important;
      font-size: 0.85vw;
      font-family: "Nunito Sans", sans-serif;
      width: 3vw;
      height: 3vw;
      flex: 0 0 3vw;
      @include lg {
        width: 40px;
        height: 40px;
        flex: 0 0 40px;
        font-size: 14px;
      }
      @include sm {
        font-size: 12px;
        width: 32px;
        height: 32px;
        flex: 0 0 32px;
      }
    }
    .css-xl8mvw {
      color: rgba(255, 255, 255, 0.2) !important;
      font-size: 0.85vw;
      font-family: "Nunito Sans", sans-serif;
      width: 3vw;
      height: 3vw;
      flex: 0 0 3vw;
      @include lg {
        width: 40px;
        height: 40px;
        flex: 0 0 40px;
        font-size: 14px;
      }
      @include sm {
        font-size: 12px;
        width: 32px;
        height: 32px;
        flex: 0 0 32px;
      }
    }

    .css-1jsy6pn-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
      background: var(--color-dark-600) !important;
    }
    .css-4ggly9.Mui-selected {
      background: var(--color-dark-600) !important;
    }
    .css-4ggly9.Mui-selected {
      background: var(--color-dark-600) !important;
    }
    .css-5m299p-MuiButtonBase-root-MuiPickersDay-root:hover {
      background: var(--color-dark-500) !important;
    }
    .css-xl8mvw:hover {
      background: var(--color-dark-500) !important;
    }
    .css-1jsy6pn-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover {
      background: var(--color-dark-600) !important;
    }
    .css-4ggly9.Mui-selected:hover {
      background: var(--color-dark-600) !important;
    }
    .css-4ggly9.Mui-selected:hover {
      background: var(--color-dark-600) !important;
    }
    .css-1jsy6pn-MuiButtonBase-root-MuiPickersDay-root {
      color: var(--color-white);
      font-size: 0.85vw;
      border: 1px solid var(--color-dark-600);
      font-family: "Nunito Sans", sans-serif;
      background-color: transparent !important;
      width: 3vw;
      height: 3vw;
      flex: 0 0 3vw;
      @include lg {
        width: 40px;
        height: 40px;
        flex: 0 0 40px;
        font-size: 12px;
      }
      @include sm {
        width: 32px;
        height: 32px;
        flex: 0 0 32px;
      }
    }
    .css-4ggly9 {
      color: var(--color-white);
      font-size: 0.85vw;
      border: 1px solid var(--color-dark-600);
      font-family: "Nunito Sans", sans-serif;
      background-color: transparent !important;
      width: 3vw;
      height: 3vw;
      flex: 0 0 3vw;
      @include lg {
        width: 40px;
        height: 40px;
        flex: 0 0 40px;
        font-size: 12px;
      }
      @include sm {
        width: 32px;
        height: 32px;
        flex: 0 0 32px;
      }
    }

    .css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel {
      width: 3vw;
      height: 3vw;
      flex: 0 0 3vw;
      color: var(--color-white);
      font-size: 0.85vw;
      font-family: "Nunito Sans", sans-serif;
      @include lg {
        width: 40px;
        height: 40px;
        flex: 0 0 40px;
        font-size: 14px;
      }
      @include sm {
        width: 32px;
        height: 32px;
        flex: 0 0 32px;
      }
    }
    .css-10qkmc3 {
      width: 3vw;
      height: 3vw;
      flex: 0 0 3vw;
      color: var(--color-white);
      font-size: 0.85vw;
      font-family: "Nunito Sans", sans-serif;
      @include lg {
        width: 40px;
        height: 40px;
        flex: 0 0 40px;
        font-size: 14px;
      }
      @include sm {
        width: 32px;
        height: 32px;
        flex: 0 0 32px;
      }
    }
    .css-1nkg345-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button {
      border: 1px solid var(--color-white);
      border-radius: 50%;
      width: 2vw;
      height: 2vw;
      transition: 0.4s ease;
      &:hover {
        transform: scale(1.05);
        @include md {
          transform: scale(1);
        }
      }
      @include lg {
        width: 32px;
        height: 32px;
      }
    }
    .css-1fklenr {
      border: 1px solid var(--color-white);
      border-radius: 50%;
      width: 2vw;
      height: 2vw;
      transition: 0.4s ease;
      &:hover {
        transform: scale(1.05);
        @include md {
          transform: scale(1);
        }
      }
      @include lg {
        width: 32px;
        height: 32px;
      }
    }
    .css-kg9q0s-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button {
      border: 1px solid var(--color-white);
      border-radius: 50%;
      width: 2vw;
      height: 2vw;
      margin-right: 0.1vw;
      transition: 0.4s ease;
      &:hover {
        transform: scale(1.05);
        @include md {
          transform: scale(1);
        }
      }
      @include lg {
        width: 32px;
        margin-right: 1px;
        height: 32px;
      }
    }
    .css-kg9q0s-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button {
      border: 1px solid var(--color-white);
      border-radius: 50%;
      width: 2vw;
      height: 2vw;
      margin-right: 0.1vw;
      transition: 0.4s ease;
      &:hover {
        transform: scale(1.05);
        @include md {
          transform: scale(1);
        }
      }
      @include lg {
        width: 32px;
        margin-right: 1px;
        height: 32px;
      }
    }
    .css-11wxb {
      border: 1px solid var(--color-white);
      border-radius: 50%;
      width: 2vw;
      height: 2vw;
      margin-right: 0.1vw;
      transition: 0.4s ease;
      &:hover {
        transform: scale(1.05);
        @include md {
          transform: scale(1);
        }
      }
      @include lg {
        width: 32px;
        margin-right: 1px;
        height: 32px;
      }
    }
    .css-1cafy48-MuiPickersSlideTransition-root-MuiDayCalendar-slideTransition {
      overflow: inherit;
      min-height: 100%;
    }
    .css-2ldko6 {
      overflow: inherit;
      min-height: 100%;
    }
    .css-1vooibu-MuiSvgIcon-root {
      fill: var(--color-white);
    }
    .css-1cw4hi4 {
      fill: var(--color-white);
    }
    .css-cwhad8-MuiDateCalendar-root {
      width: 100%;
      height: 100%;
      min-height: 100%;
      max-height: 100%;
      @include lg {
        height: 440px;
      }
      @include sm {
        height: 420px;
      }
    }
    .css-1080di7 {
      width: 100%;
      height: 100%;
      min-height: 100%;
      max-height: 100%;
      @include lg {
        height: 440px;
      }
      @include sm {
        height: 420px;
      }
    }
    .css-1jsy6pn-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected) {
      border-color: var(--color-dark-700) !important;
    }
    .css-4ggly9:not(.Mui-selected) {
      border-color: var(--color-dark-700) !important;
    }

    .css-5m299p-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover {
      background-color: var(--color-dark-700) !important;
    }
    .css-xl8mvw.Mui-selected:hover {
      background-color: var(--color-dark-700) !important;
    }
    .css-i5q14k-MuiDayCalendar-header {
      width: 100%;
      margin: 0.8vw 0 0 0;
      justify-content: space-between;
      @include lg {
        margin-top: 15px;
      }
    }
    .css-1tkx1wf-MuiSvgIcon-root-MuiPickersCalendarHeader-switchViewIcon {
      fill: var(--color-inwhite);
      display: none;
    }
    .css-sldnni {
      fill: var(--color-inwhite);
      display: none;
    }
    .css-1n2mv2k {
      width: 100%;
      margin: 0.8vw 0 0 0;
      justify-content: space-between;
      @include lg {
        margin-top: 15px;
      }
    }
    .css-flbe84-MuiDayCalendar-weekContainer {
      width: 100%;
      margin: 0.2vw 0;
      justify-content: space-between;
      @include lg {
        margin: 15px 0;
      }
      @include sm {
        margin: 10px 0;
      }
    }
    .css-mvmu1r {
      width: 100%;
      margin: 0.2vw 0;
      justify-content: space-between;
      @include lg {
        margin: 15px 0;
      }
      @include sm {
        margin: 10px 0;
      }
    }
    .css-mvmu1r {
      width: 100%;
      margin: 0.2vw 0;
      justify-content: space-between;
      @include lg {
        margin: 15px 0;
      }
      @include sm {
        margin: 10px 0;
      }
    }
    .css-12p2adl-MuiDayCalendar-monthContainer {
      overflow: inherit;
    }
    .css-i6bazn {
      overflow: inherit;
    }
  }
  &__list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    position: absolute;
    bottom: 2vw;
    flex-wrap: wrap;
    z-index: 2;
    align-items: center;
    grid-gap: 0.4vw;
    @include lg {
      grid-gap: 6px;
      margin: 0;
      bottom: 22px;
    }
    li {
      display: flex;
      line-height: 1.6vw;
      border-radius: 2vw;
      font-size: 0.75vw;
      color: var(--color-white);
      max-width: fit-content;
      padding: 0 0.9vw;
      align-items: center;
      justify-content: center;
      border: 1px solid transparent;
      @include lg {
        line-height: 25px;
        padding: 0 12px;

        border-radius: 30px;
        font-size: 13px;
      }
      @include md {
        line-height: 22px;
        padding: 0 10px;

        border-radius: 30px;
        font-size: 12px;
      }
      &:nth-child(1) {
        border-color: var(--color-green-100);
      }
      &:nth-child(2) {
        border-color: var(--color-orange-100);
      }
      &:nth-child(3) {
        border-color: var(--color-blue-100);
      }
      &:nth-child(4) {
        border-color: var(--color-violet-100);
      }
    }
  }
  &__slots {
    margin-top: 5vw;
    @include lg {
      margin-top: 24px;
    }
    &-head {
      color: var(--color-white);
      font-size: 1vw;
      display: block;
      font-weight: 400;
      line-height: 1vw;
      @include lg {
        font-size: 16px;
        line-height: 16px;
      }
    }
    &-item {
      &.green-tag .peaches-calendar__slots-line {
        background: var(--color-green-100);
      }
      &.blue-tag .peaches-calendar__slots-line {
        background: var(--color-blue-100);
      }
      &.orange-tag .peaches-calendar__slots-line {
        background: var(--color-orange-100);
      }
      &.violet-tag .peaches-calendar__slots-line {
        background: var(--color-violet-100);
      }
      span {
        color: var(--color-white);
        font-size: 0.8vw;
        display: block;
        font-weight: 400;
        white-space: nowrap;
        line-height: 0.8vw;
        display: block;
        margin-top: 0.6vw;
        @include lg {
          margin-top: 8px;
          line-height: 17px;
          font-size: 13px;
        }
      }
    }
    &-line {
      width: 100%;
      height: 1px;
    }
    &-row {
      margin-right: 2vw;
      display: grid;
      grid: auto / repeat(4, 1fr);
      grid-gap: 1.5vw;
      margin-top: 1.5vw;
      @include lg {
        margin-top: 24px;
        grid-gap: 24px;
      }
      @include xs {
        display: flex;
        grid-gap: 12px;
        flex-wrap: wrap;
      }
    }
  }
  &__info {
    background-color: var(--color-black);
    border-radius: 1vw;
    padding: 2vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    opacity: 0;
    animation-duration: 0.1s;
    animation-delay: 2s;
    animation: count-fade 0.5s ease forwards;
    @include lg {
      border-radius: 14px;
      padding: 28px 18px;
    }
    .studio-stats__button img {
      filter: invert(1);
    }
    &-date {
      color: var(--color-gray-600);
      font-size: 1.1vw;
      display: block;
      font-weight: 400;
      line-height: 1.2vw;
      @include lg {
        font-size: 17px;
        line-height: 20px;
      }
    }
    &-name {
      color: var(--color-white);
      font-size: 1.7vw;
      max-width: 16vw;
      line-height: 2vw;
      display: block;
      font-weight: 400;
      margin: 1.5vw 0;
      @include lg {
        font-size: 28px;
        max-width: 100%;
        margin: 20px 0 25px 0;
        line-height: 37px;
      }
      @include sm {
        font-size: 26px;
        line-height: 34px;
      }
    }
  }
  &__title {
    font-size: 2.2vw;
    letter-spacing: 0;
    overflow: hidden;
    color: var(--color-white);
    span {
      display: flex;
      position: relative;
      transition: transform 1.2s cubic-bezier(0.19, 1, 0.22, 1);
      &::after {
        content: attr(data-label);
        position: absolute;
        top: 110%;
        left: 0;
        will-change: transform;
      }
    }
    @include lg {
      font-size: 32px;
    }

    @include sm {
      font-size: 20px;
    }
  }
  &__row {
    display: flex;

    flex-grow: 1;
    grid-gap: 1vw;

    @include lg {
      display: flex;
      flex-direction: column;
      grid-gap: 16px;
    }
    @include md {
      grid: auto / 1fr;
    }
  }
  &__text {
    background-color: var(--color-black);
    border-radius: 1vw;
    padding: 2vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1vw;
    font-weight: 400;
    line-height: 1.4vw;
    color: var(--color-white);
    opacity: 0;
    animation-duration: 0.1s;
    animation-delay: 2s;
    animation: count-fade 0.5s ease forwards;
    @include lg {
      font-size: 15px;
      border-radius: 14px;
      line-height: 22px;

      display: flex;
      align-items: flex-start;
      background: transparent;
      color: var(--color-dark);
    }
  }
  &__box {
    display: grid;
    grid: 11vw 1fr / auto;
    flex: 0 0 27.5vw;
    grid-gap: 1vw;

    @include lg {
      grid: auto / auto;
      grid-gap: 16px;
      order: 1;
    }
  }
  &__information {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5vw;
    position: absolute;
    bottom: 0;
    cursor: pointer;
    left: 0;
    &:hover {
      .peaches-calendar__title span {
        transform: translateY(-110%);
      }
      img {
        transform: scale(1.05);
        @include md {
          transform: scale(1);
        }
      }
    }

    @include lg {
      display: flex;
      height: 100%;
      align-items: center;
      padding: 0 18px 0 90px;
    }
    @include sm {
      padding: 0 18px 0 80px;
    }
  }
  &__overlay {
    background-color: var(--color-black);
    border-radius: 1vw;
    padding: 2vw;
    overflow: hidden;
    position: relative;
    opacity: 0;
    animation-duration: 0.15s;
    animation-delay: 2s;
    animation: count-fade 0.8s ease forwards;

    .studio-stats__button img {
      filter: brightness(0) invert(1);
    }

    &::before {
      content: "";
      display: block;
      padding-bottom: 75%;
      @include lg {
        padding-bottom: 54px;
      }
    }
    @include lg {
      padding: 16px 0;
      border-radius: 12px;
    }
    @include sm {
      padding: 8px 0;
      border-radius: 12px;
    }

    img,
    video {
      &.bloggers {
        object-position: top;
        // object-position: 0 0;
        width: 100%;
        height: 100%;
        filter: opacity(0.8);
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        object-fit: cover;
        @include lg {
          width: 60px;
          height: 60px;
          top: 50%;
          left: 18px;
          transform: translate(0, -50%);
          border-radius: 50%;
        }
        @include sm {
          width: 50px;
          height: 50px;
        }
      }
    }
  }
  &__boxes {
    display: grid;
    grid: auto / 1fr 33vw;
    margin-top: 1vw;
    position: relative;
    grid-gap: 1vw;
    opacity: 0;
    animation-duration: 0.15s;
    animation-delay: 2s;
    animation: count-fade 0.8s ease forwards;

    @include lg {
      grid: auto / 1fr;
      margin-top: 16px;
      grid-gap: 12px;
    }
  }
  &__youtube {
    background-color: var(--color-black);
    border-radius: 1vw;
    padding: 2vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.8vw;
    color: var(--color-white);
    font-weight: 500;
    opacity: 0;
    animation-duration: 0.1s;
    animation-delay: 2s;
    animation: count-fade 0.5s ease forwards;
    .count {
      opacity: 0;
      animation-duration: 1s;
      animation-delay: 2s;
      animation: count-fade 1s ease forwards;
      @keyframes count-fade {
        to {
          opacity: 1;
        }
      }
    }

    @include lg {
      padding: 18px;
      font-size: 20px;
      border-radius: 14px;
    }

    svg {
      transition: 0.4s ease;
    }
    &:hover {
      color: var(--color-white);
      svg {
        transform: scale(1.05);
        @include md {
          transform: scale(1);
        }
      }
    }
    &-block {
      display: flex;
      align-items: center;
      grid-gap: 1vw;
      svg {
        width: 2.7vw;
        height: 2vw;
        @include lg {
          width: 40px;
          height: 24px;
        }
        @include sm {
          width: 32px;
        }
      }
    }
  }
  &__person {
    display: flex;
    flex-direction: column;
    grid-gap: 1vw;
    flex: 1;

    @include lg {
      order: 2;
      grid-gap: 16px;
    }
  }
  &__social {
    background-color: var(--color-black);
    border-radius: 1vw;
    padding: 2vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    opacity: 0;
    animation-duration: 0.1s;
    animation-delay: 2s;
    animation: count-fade 0.5s ease forwards;

    @include lg {
      padding: 18px;
      border-radius: 14px;
    }
    &-list {
      list-style-type: none;
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      grid-gap: 0.5vw;
      @include lg {
        grid-gap: 8px;
      }
      li {
        display: inline-block;
        border-radius: 0.5vw;
        width: 2.5vw;
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        height: 2.5vw;
        transition: 0.4s ease;
        background: var(--color-dark-600);
        a {
          width: 100%;
          transition: 0.4s ease;
          height: 100%;
          display: block;
        }
        @include lg {
          width: 32px;
          border-radius: 8px;
          height: 32px;
        }
        &:last-child {
          margin-right: 0;
        }
        &:hover svg {
          opacity: 0.8;
        }
        &:hover {
          filter: opacity(0.9);
        }
        svg {
          transition: 0.4s ease;
          width: 0.8vw;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          height: 0.8vw;
          fill: var(--color-white);
          @include lg {
            width: 12px;
            height: 12px;
          }
        }
      }
    }
    &-info {
      span {
        color: rgba(255, 255, 255, 0.7);
        font-size: 0.8vw;
        font-weight: 400;
        line-height: 1vw;
        @include lg {
          font-size: 14px;
          line-height: 20px;
        }
      }
      p {
        color: var(--color-white);

        font-size: 1.26vw;
        display: block;
        font-weight: 400;
        margin-top: 0.7vw;
        line-height: 1.2vw;
        @include lg {
          font-size: 21px;
          line-height: 30px;
        }
      }
    }
  }
}
