@use "~mixins" as *;
.footer-studio {
  padding: clamp(32px, 3vw, 50px) 0;

  .studio-stats__button.dark .btn-outline {
    + img {
      filter: var(--filter-button);
      opacity: var(--opacity-button);
    }
  }
  @include xl {
    .studio-stats__button.dark .btn-outline {
      display: flex;
      + img {
        display: block;
      }
    }
    .studio-stats__button a.mobile {
      display: none;
    }
  }
  @include md {
    padding: 32px 0 40px 0;
  }
  &__content {
    border-radius: clamp(16px, 1.1vw, 20px);
    background: var(--color-gray-450);
    padding: clamp(24px, 2.5vw, 48px);
    @include xl {
      border-radius: 16px;
      position: relative;
    }
  }

  &__logo {
    @include xl {
      display: none;
    }
    img {
      width: 10vw;
      filter: var(--filter-invert);

      @include xl {
        width: 160px;
      }
    }
  }
  &__more {
    margin-top: 1.5vw;
    @include xl {
      margin-top: 0;
    }
  }
  &__desc {
    font-size: 0.8vw;
    line-height: 1.3vw;
    margin-top: 1vw;
    font-weight: 400;
    color: var(--color-dark);
    @include xl {
      font-size: 13px;
      line-height: 20px;
      margin-top: 0;
    }
  }
  &__inwrap {
    margin-top: clamp(34px, 3vw, 55px);
    display: flex;

    align-items: flex-end;
    justify-content: space-between;
    @include md {
      margin-top: 0;
    }
  }
  &__list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    grid-gap: 0.5vw;
    @include xl {
      grid-gap: 8px;
    }
    li {
      display: inline-block;
      border-radius: 0.5vw;
      width: clamp(36px, 2.4vw, 42px);
      height: clamp(36px, 2.4vw, 42px);
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;
      background: var(--color-dark-600);
      @include xl {
        border-radius: 8px;
      }
      &:last-child {
        margin-right: 0;
      }
      &:hover svg {
        opacity: 0.8;
      }
      svg {
        transition: 0.4s ease;
        width: clamp(12px, 0.8vw, 16px);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: clamp(12px, 0.8vw, 16px);
        fill: var(--color-white);
      }
    }
  }
  &__main {
    display: flex;
    align-items: flex-end;
    grid-gap: clamp(32px, 5vw, 100px);
    justify-content: space-between;
    @include lg {
      align-items: flex-end;
    }
    @include md {
      flex-direction: column;
    }
    .studio-contact__item {
      justify-content: flex-start;
      align-items: flex-start;
      @include lg {
        &:nth-child(1) {
          display: none;
        }
      }
    }
    .studio-contact__subtitle {
      padding: 0;
      text-align: left;
    }
    .footer-studio__wrap {
      flex: 1;
      width: 37%;
      flex: 0 0 37%;
      @include full {
        width: 40%;
        flex: 0 0 40%;
      }
      @include xxl {
        width: 45%;
        flex: 0 0 45%;
      }
      @include xl {
        flex: 0 0 52%;
        width: 52%;
      }

      @include lg {
        flex: 0 0 40%;
        width: 40%;
      }
      @include md {
        width: 100%;
        flex: 0 0 100%;
      }
    }
    .studio-contact__block {
      display: flex;
      justify-content: space-between;
      @include lg {
        display: none;
      }
    }
  }
  &__inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-weight: 500;
    font-size: 0.8vw;
    line-height: 1vw;
    grid-gap: 6.2vw;
    @include md {
      border-width: 1px;
      line-height: 30px;
      border-radius: 8px;
      font-size: 14px;
    }
    @include md {
      font-size: 12px;
      line-height: 20px;
      margin-bottom: 12px;
      grid-gap: 20px;
    }

    .studio-stats__button img {
      width: clamp(36px, 2.4vw, 46px);
      height: clamp(36px, 2.4vw, 46px);
    }
    .studio-stats__button .btn-outline a {
      font-size: clamp(14px, 0.88vw, 17px);
      line-height: clamp(34px, 2.4vw, 44px);
      padding: 0 clamp(24px, 2.1vw, 40px);
      border-radius: clamp(8px, 0.5vw, 10px);
      text-transform: inherit;
      @include lg {
        border-width: 1px;
      }
    }
    h2 {
      color: var(--color-dark);
      font-size: clamp(25px, 2.8vw, 50px);
      font-weight: 400;
      position: relative;
      line-height: 1.3;
      text-transform: inherit;
      @include xl {
        right: 0;
      }
    }
  }
}
