.MuiMenu-paper.MuiMenu-paper {
  transition-duration: 0s !important;
  box-shadow: none;
  background-color: transparent;
  overflow: inherit !important;

  ul {
    list-style-type: none;

    margin: 1.2vw 0 0;
    padding: 0;
    background: transparent;
    border-radius: 1vw !important;
    width: calc(100% + 2.4vw);
    left: -1.2vw;
    background-color: #1b1b1b;
    position: relative;
    @include md {
      margin: 9px 0 0;
      overflow: hidden;
      border-radius: 12px !important;
      width: calc(100% + 28px);
      left: -14px;
    }
    li {
      display: flex;

      padding: 0.8vw 1.2vw;
      font-size: 1vw;
      font-family: "Nunito Sans", sans-serif;
      font-weight: 400;

      background: transparent;
      align-items: center;
      color: white;
      border-bottom: 1px solid #222;
      justify-content: space-between;
      transition: 0.4s ease;
      &:last-child {
        border: none;
      }
      @include md {
        font-size: 18px;
        padding: 8px 14px;
      }
      &:hover {
        background: transparent;
        color: rgb(206, 206, 206);
        border-color: rgb(54, 54, 54);
      }
    }
  }
}
