@use "~mixins" as *;
.peaches-login {
  padding: 5vw 0;
  height: 100vh;
  overflow: auto;
  display: grid;
  align-items: center;
  @include lg {
    padding: 64px 0;
  }

  &__title {
    font-size: 1.5vw;
    line-height: 2vw;
    color: var(--color-dark);
    display: block;
    text-align: center;
    font-weight: 500;
    margin-bottom: 2vw;
    @include lg {
      font-size: 28px;
      line-height: 32px;
      margin-bottom: 24px;
    }
  }
  &__error {
    background-color: var(--color-red);
    padding: 0.5vw 1vw;
    border-radius: 0.4vw;
    font-size: 0.8vw;
    color: var(--color-white);
    @include lg {
      font-size: 14px;
      border-radius: 10px;
      padding: 8px 16px;
    }
  }
  &__subtext {
    font-size: 0.8vw;
    line-height: 1.2vw;
    text-align: center;
    margin-top: 1vw;
    color: var(--color-dark);

    cursor: pointer;
    @include lg {
      font-size: 14px;
      margin: 24px 0 16px 0;
    }
    a {
      position: relative;
      max-width: fit-content;
      margin-left: 0.4vw;
      @include lg {
        margin-left: 4px;
      }
      &:hover {
        color: var(--color-dark);
        &::after {
          width: 100%;
        }
      }
      &::after {
        content: "";
        display: block;
        width: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 1px;
        transition: 0.4s ease;
        background-color: var(--color-dark);
      }
    }
  }
  &__logo {
    max-width: 10vw;
    margin: 1vw auto 0;
    width: 100%;
    display: block;
    object-fit: contain;
    @include lg {
      max-width: 170px;
    }
    img {
      display: block;
      width: 100%;
      filter: var(--filter-invert);
    }
  }
  &__form {
    max-width: 31vw;
    display: flex;
    flex-direction: column;
    grid-gap: 1vw;
    border-radius: 1.5vw;
    background: var(--color-gray-500);
    padding: 2.5vw 3vw;

    margin: 0 auto;
    @include lg {
      border-radius: 16px;
      max-width: 500px;
      padding: 40px;
      grid-gap: 12px;
    }
    .MuiFormControl-root css-1nrlq1o-MuiFormControl-root {
      flex: 1;
      width: 100%;
    }
    .css-v4u5dn-MuiInputBase-root-MuiInput-root {
      color: var(--color-white);
    }

    input {
      height: 3vw;
      border: 1px solid #dbdbdb;
      overflow: hidden;
      border-radius: 0.6vw;
      font-size: 0.9vw;
      color: var(--color-dark);
      padding: 0 1vw;
      transition: 0.4s ease;
      @include lg {
        height: 50px;
        font-size: 16px;
        padding: 0 16px;
        border-radius: 12px;
      }
    }
    input:focus {
      border-color: var(--color-inwhite);
    }
    .css-v4u5dn-MuiInputBase-root-MuiInput-root {
      &:before {
        display: none;
      }
      &::after {
        display: none;
      }
    }
  }
  &__icon {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 1vw;
    z-index: 10;
    width: 1.1vw;
    cursor: pointer;
    height: 1.1vw;
    width: 1.1vw;
    transition: 0.4s ease;
    opacity: 0.8;
    svg {
      path,
      circle {
        stroke: var(--color-dark);
      }
    }
    @include lg {
      width: 20px;
      height: 20px;
      right: 16px;
    }
    svg {
      width: 1.1vw;
      height: 1.1vw;
      @include lg {
        width: 20px;
        height: 20px;
      }
    }
  }
  &__button {
    font-size: 0.9vw;
    width: 100%;
    line-height: 3vw;
    cursor: pointer;
    border-radius: 0.6vw;
    text-transform: inherit;
    color: var(--color-white-100);
    border: none;
    background: var(--color-indark);
    transition: 0.4s ease;
    &:disabled {
      background: #ececec;
      color: #afafaf;
      cursor: not-allowed;
      pointer-events: all;
    }
    &:hover {
      opacity: 0.9;
    }

    @include lg {
      border-width: 1px;
      line-height: 50px;
      border-radius: 12px;
      padding: 0 16px;
      font-size: 16px;
    }
  }
}
