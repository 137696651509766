@use "./media" as *;

html.lenis {
	height: auto;
}

.lenis.lenis-smooth {
	scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
	overscroll-behavior: contain;
}

.lenis.lenis-stopped {
	overflow: hidden;
}

.lenis.lenis-scrolling iframe {
	pointer-events: none;
}

html {
	&:root {
		--f-button-next-pos: 2vw;

		--f-button-width: 2.7vw;
		--f-button-height: 2.7vw;
		--f-button-prev-pos: 2vw;
		--f-button-border-radius: 50%;
		--f-button-bg: rgb(220, 219, 219);
		--fancybox-bg: var(--color-dark);
		--color-white: rgb(255, 255, 255);
		--color-inwhite: rgb(255, 255, 255);
		--color-dark: #0e0e0e;
		--color-dark-200: #fff;
		--color-indark: #0e0e0e;
		--color-dark-300: #1c1b1b;
		--color-dark-350: #1c1b1b;
		--color-dark-400: #1c1b1b;
		--color-dark-500: #202020;
		--color-dark-520: #e3e3e3;
		--color-dark-550: #202020;
		--color-dark-900: #202020;
		--color-dark-600: #222122;
		--color-dark-700: #202020;
		--color-dark-800: #161616;
		--color-black: #161515;
		--color-black-100: rgba(0, 0, 0, 0.1);
		--color-black-200: rgba(0, 0, 0, 0.2);
		--color-black-300: rgba(0, 0, 0, 0.3);
		--color-black-400: rgba(0, 0, 0, 0.4);
		--color-black-500: rgba(0, 0, 0, 0.5);
		--color-black-600: rgba(0, 0, 0, 0.6);
		--color-black-700: rgba(0, 0, 0, 0.7);
		--color-black-800: rgba(0, 0, 0, 0.8);
		--color-black-900: rgba(0, 0, 0, 0.9);

		--color-gray-50: rgb(249, 249, 249);
		--color-gray-100: rgb(245, 245, 245);
		--color-gray-150: rgb(220, 220, 220);
		--color-gray-200: rgba(15, 15, 15, 0.8);
		--color-gray-250: rgb(228, 224, 219);
		--color-gray-300: rgba(256, 256, 256, 0.7);
		--color-gray-350: rgb(226, 226, 226);
		--color-gray-400: rgb(229, 228, 228);
		--color-gray-450: #f1f1f1;
		--color-gray-460: #f1f1f1;
		--color-gray-500: #f6f6f6;
		--color-gray-600: #5e5e5e;
		--color-rose: #f664a2;
		--color-blue: #024a78;
		--color-blue-300: #186797;
		--color-orange-300: #dc0f00;
		--color-red: #d80000;
		--color-orange: #f2542d;
		--color-gray-30: #fff;
		--color-green-100: #8af600;
		--color-blue-100: #51c0ff;
		--color-orange-100: #ffbe3f;
		--color-violet-100: violet;

		--vh: 100vh;
		--scroll-bg: rgba(0, 0, 0, 0.2);
		--color-dark-450: #e5e5e5;
		--filter-invert-dark: invert(1);
		--border-dark: rgba(0, 0, 0, 0.1);
		--font-sm: clamp(14px, 1vw, 16px);
		--line-h-sm: 1.5vw;
		--color-white-100: white;
		--fast-transition: all 0.4s cubic-bezier(0.7, 0, 0.3, 1);
		--smooth-transition: all 0.7s cubic-bezier(0.7, 0, 0.3, 1);
		--slow-transition: all 1.1s cubic-bezier(0.375, 0.885, 0.32, 1.275);
		--checked-dark: black;
		@include md {
			--font-sm: 14px;
			--line-h-sm: 20px;
			--f-button-next-pos: 20px;

			--f-button-width: 48px;
			--f-button-height: 48px;
			--f-button-prev-pos: 20px;
		}
	}
	&.dark {
		&:root {
			--color-dark-450: #1b1b1b;
			--checked-dark: transparent;
			--border-dark: rgba(256, 256, 256, 0.1);
			--f-button-next-pos: 2vw;
			--f-button-width: 2.7vw;
			--f-button-height: 2.7vw;
			--f-button-prev-pos: 2vw;
			--f-button-border-radius: 50%;
			--f-button-bg: rgb(220, 219, 219);
			--fancybox-bg: var(--color-dark);
			--color-white: #fff;
			--scroll-bg: rgba(256, 256, 256, 0.2);
			--color-gray-30: #0d0d0d;
			--color-dark: #fff;
			--color-inwhite: white;
			--color-white-100: #0d0d0d;
			--color-dark-300: #1c1b1b;
			--color-dark-400: #1c1b1b;
			--color-dark-500: #1e1e1e;
			--color-dark-520: #1e1e1e;
			--color-dark-550: #ffffff;
			--color-dark-200: #000;
			--color-dark-350: #1c1b1b;
			--color-dark-900: #ffffff;
			--color-dark-600: #222122;
			--color-dark-700: #202020;
			--color-dark-800: #161616;
			--color-black: #161515;
			--color-indark: #fff;
			--filter-invert: brightness(0) invert(1);
			--filter-invert-dark: brightness(0) invert(1);
			--color-black-100: rgba(0, 0, 0, 0.1);
			--color-black-200: rgba(0, 0, 0, 0.2);
			--color-black-300: rgba(0, 0, 0, 0.3);
			--color-black-400: rgba(0, 0, 0, 0.4);
			--color-black-500: rgba(0, 0, 0, 0.5);
			--color-black-600: rgba(0, 0, 0, 0.6);
			--color-black-700: rgba(0, 0, 0, 0.7);
			--color-black-800: rgba(0, 0, 0, 0.8);
			--color-black-900: rgba(0, 0, 0, 0.9);

			--color-gray-50: rgb(249, 249, 249);
			--color-gray-100: rgb(245, 245, 245);
			--color-gray-150: rgb(220, 220, 220);
			--color-gray-200: rgba(15, 15, 15, 0.8);
			--color-gray-250: rgb(228, 224, 219);
			--color-gray-300: rgba(256, 256, 256, 0.7);
			--color-gray-350: rgb(226, 226, 226);
			--color-gray-400: rgb(229, 228, 228);
			--color-gray-450: #161515;
			--color-gray-460: #fff;
			--color-gray-500: #202020;
			--color-gray-600: #5e5e5e;
			--color-rose: #f664a2;
			--color-blue: #024a78;
			--color-blue-300: #186797;
			--color-orange-300: #dc0f00;
			--color-red: #d80000;
			--color-orange: #f2542d;

			--color-green-100: #8af600;
			--color-blue-100: #51c0ff;
			--color-orange-100: #ffbe3f;
			--color-violet-100: violet;

			--vh: 100vh;

			--font-sm: clamp(14px, 1vw, 16px);
			--line-h-sm: 1.5vw;

			--fast-transition: all 0.4s cubic-bezier(0.7, 0, 0.3, 1);
			--smooth-transition: all 0.7s cubic-bezier(0.7, 0, 0.3, 1);
			--slow-transition: all 1.1s cubic-bezier(0.375, 0.885, 0.32, 1.275);
			@include md {
				--font-sm: 14px;
				--line-h-sm: 20px;
				--f-button-next-pos: 20px;

				--f-button-width: 48px;
				--f-button-height: 48px;
				--f-button-prev-pos: 20px;
			}
		}
	}
}
a {
	color: inherit;
	text-decoration: inherit;
}

figure {
	margin: 0;
}
html.has-scroll-smooth {
	bottom: 0;
	left: 0;
	overflow: hidden;
	position: fixed;
	right: 0;
	top: 0;
}

html.has-scroll-dragging {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
	padding: 0;
	margin: 0;
}
.fade-title {
	max-width: fit-content;
	width: 100%;
	position: relative;
	-webkit-perspective: 600px;
	perspective: 600px;
	font-family: "Nunito Sans", sans-serif;
	font-weight: 600;
	z-index: 98;
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	span {
		display: inline-block;
		opacity: 0;
		z-index: 999;
		transform: translateY(100%) rotateX(-70deg);
		transform-origin: center top;
		transform-style: preserve-3d;
		transition:
			opacity 0s cubic-bezier(0.215, 0.61, 0.355, 1),
			transform 0s cubic-bezier(0.215, 0.61, 0.355, 1);
		&.orange-text {
			color: var(--color-orange);
		}
	}
	@include md {
		span {
			opacity: 1;
			transform: none;
		}
	}
	&.is-new {
		span {
			opacity: 1;
			transform: none;
			transition-duration: 1.4s;
			@for $i from 1 through 100 {
				&:nth-child(#{$i}) {
					transition-delay: (0.2s * $i);
				}
			}
		}
	}
}
h1 {
	font-size: clamp(32px, 3vw, 57px);
	line-height: 1.2;
	font-weight: 400;
	font-family: "Nunito Sans", sans-serif;

	color: var(--color-dark);
	letter-spacing: -0.1vw;
}
h2 {
	font-size: 4.5vw;
	font-family: "Nunito Sans", sans-serif;
	line-height: 5.5vw;
	font-weight: 400;
	text-transform: uppercase;

	color: var(--color-dark);
	font-weight: 600;
	@include lg {
		font-size: 48px;
		line-height: 58px;
	}
}
h3 {
	font-size: 2.4vw;
	line-height: 3.2vw;
	font-family: "Nunito Sans", sans-serif;
	font-weight: 400;
	color: var(--color-dark);
	@include lg {
		font-size: 36px;
		line-height: 46px;
	}
}
h4 {
	font-size: 5vw;
	font-family: "Nunito Sans", sans-serif;
	line-height: 7vw;
	font-weight: 400;
	text-transform: uppercase;
	color: var(--color-white);
}
h5 {
	font-size: 2.8vw;
	font-family: "Nunito Sans", sans-serif;
	line-height: 3.8vw;
	font-weight: 400;
	color: var(--color-white);
	@include lg {
		font-size: 30px;
		line-height: 40px;
	}
	@include sm {
		font-size: 32px;
		line-height: 42px;
	}
}
h6 {
	font-size: 0.68vw;
	font-family: "Nunito Sans", sans-serif;
	font-weight: 400;
	line-height: 1.6vw;
	text-transform: uppercase;
	color: var(--color-white);
}

html.has-scroll-smooth body {
	overflow: hidden;
}

.has-scroll-smooth [data-scroll-container] {
	min-height: 100vh;
}

[data-scroll-direction="horizontal"] [data-scroll-container] {
	height: 100vh;
	display: inline-block;
	white-space: nowrap;
}

[data-scroll-direction="horizontal"] [data-scroll-section] {
	display: inline-block;
	vertical-align: top;
	white-space: nowrap;
	height: 100%;
}

.c-scrollbar {
	position: absolute;
	right: 0;
	top: 0;
	width: 11px;
	height: 100%;
	transform-origin: center right;
	transition:
		transform 0.3s,
		opacity 0.3s;
	opacity: 0;
}
.c-scrollbar:hover {
	transform: scaleX(1.45);
}
.c-scrollbar:hover,
.has-scroll-scrolling .c-scrollbar,
.has-scroll-dragging .c-scrollbar {
	opacity: 1;
}
[data-scroll-direction="horizontal"] .c-scrollbar {
	width: 100%;
	height: 10px;
	top: auto;
	bottom: 0;
	transform: scaleY(1);
}
[data-scroll-direction="horizontal"] .c-scrollbar:hover {
	transform: scaleY(1.3);
}

.c-scrollbar_thumb {
	position: absolute;
	top: 0;
	right: 0.4vw;
	background-color: var(--color-black);
	opacity: 1;
	width: 7px;
	border-radius: 10px;
	margin: 2px;
}

html.has-scroll-smooth [data-scroll-container],
html.has-scroll-smooth [data-scroll-section] {
	perspective: 1px;
	overflow: hidden !important;
}
.has-scroll-dragging .c-scrollbar_thumb {
	cursor: grabbing;
}
[data-scroll-direction="horizontal"] .c-scrollbar_thumb {
	right: auto;
	bottom: 0;
}

.container {
	width: 100%;
	margin: 0 auto;
	padding: 0 3vw;
	@include md {
		padding: 0 22px;
	}
}
html {
	-moz-text-size-adjust: none;
	-webkit-text-size-adjust: none;
	text-size-adjust: none;
	width: 100%;
	height: 100%;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-overflow-scrolling: touch;
}
input:-webkit-autofill {
	font-family: "CeraPro";
	-webkit-text-fill-color: var(--color-dark);
	-webkit-box-shadow: 0 0 0 1000px var(--color-gray-500) inset;
}
input,
textarea,
button,
a {
	font-family: "Nunito Sans", sans-serif !important;
	&:focus {
		outline: none;
	}
}

* {
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased !important;
}

body {
	margin: 0;
	padding: 0;
	width: 100%;
	font-weight: 400;
	font-family: "Nunito Sans", sans-serif;
	height: 100%;
	color: var(--color-black);
	font-variant: inherit;
	font-feature-settings: inherit;
	background: var(--color-gray-30);
	min-height: 100%;
	&.is-hidden {
		overflow: hidden;
	}
	&.hide-scrollbar {
		.nav-header {
			opacity: 0;
			visibility: hidden;
		}
	}
}

main {
	flex-grow: 1;
	width: 100%;
	position: relative;
	height: 100%;
	&.main-page {
		.studio-faces__head {
			@include md {
				top: 120px;
				z-index: 9;
			}
		}
		.studio-project__pages {
			padding: 4vw 0 3vw 0;
			@include md {
				padding: 16px 0;
				.studio-faces__head {
					@include md {
						position: absolute;
						color: var(--color-white);
						top: 120px;
						z-index: 9;
					}
				}
			}
			.btn-outline {
				position: absolute;
				left: 50%;
				bottom: 3.5vw;
				transform: translate(-50%, 0);
				display: flex;
				justify-content: flex-start;
				align-items: flex-end;

				a {
					font-size: 0.85vw;
					padding: 0 1.5vw;
					text-transform: inherit;
					color: var(--color-white);
					&:hover {
						border-color: var(--color-white);
					}
					@include md {
						line-height: 34px;
						padding: 0 20px;
						font-size: 15px;
					}
				}

				@include md {
					bottom: 70px;
				}
			}
			.studio-project__grid {
				border-radius: 1.5vw;
				background: var(--color-dark);
				padding: 3vw 2vw 8vw 2vw;

				position: relative;
				@include md {
					width: 100%;
					position: relative;
					width: calc(100% + 44px);
					left: -22px;
					padding: 110px 22px 150px 22px;
					border-radius: 0;
				}
			}
			.studio-project__head {
				margin: 0;
			}
			.studio-project__theme,
			.studio-project__subhead {
				color: #e8e8e8;
			}
			.studio-project__wrapper span.year {
				color: #e8e8e8;
			}
		}
	}
}

// . {
// 	opacity: 0;
// 	transition: 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);

// 	&.is-new {
// 		transition-delay: 0.5s;
// 		transition-duration: 1.2s;
// 		opacity: 1;
// 		transform: none;
// 		@include md {
// 			transition-delay: 0.5s;
// 		}
// 	}
// }
.fade-opacity {
	opacity: 0;
	transition: 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);

	&.is-new {
		transition-delay: 0.6s;
		transition-duration: 1.6s;
		opacity: 1;
		transform: none;
		@include md {
			transition-delay: 0.3s;
			transition-duration: 1.5s;
		}
	}
}

@keyframes enter {
	0% {
		opacity: 0;
		transform: translateY(10px);
	}

	to {
		opacity: 1;
		transform: none;
	}
}

.btn-outline {
	a,
	button {
		font-size: 0.78vw;
		line-height: 2vw;
		padding: 0 1vw;
		display: flex;
		max-width: fit-content;
		text-transform: uppercase;
		justify-content: center;
		align-items: center;
		margin: 0 auto;
		border-radius: 0.7vw;
		font-weight: 400;
		color: var(--color-black);
		text-decoration: none;
		overflow: hidden;
		transition: 0.4s ease;
		border: 1px solid var(--color-gray-400);
		&:hover span {
			transform: translateY(-110%);
		}
		&:hover {
			border-color: var(--color-black-800);
		}
		span {
			display: flex;
			align-items: center;
			grid-gap: 0.3vw;
			position: relative;
			transition: transform 1.2s cubic-bezier(0.19, 1, 0.22, 1);
			&::after {
				content: attr(data-label);
				position: absolute;
				top: 110%;
				left: 0;
				will-change: transform;
			}
		}
		@include md {
			border-width: 1px;
			line-height: 34px;
			border-radius: 10px;
			padding: 0 36px;
			font-size: 13px;
		}
	}
}

i.arrow-icon-up {
	border: solid var(--color-white);
	border-width: 2px 2px 0px 0px;
	box-sizing: border-box;
	display: block;
	position: relative;
	height: 0.4vw;
	width: 0.4vw;
	line-height: 0.4vw;
	@include md {
		display: none;
	}
}

i.arrow-icon-up::after {
	content: "";
	position: absolute;
	width: 0.6vw;
	height: 2px;
	top: 0.18vw;
	left: -0.2vw;
	background-color: var(--color-white);
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	@include md {
		width: 13px;
		height: 1px;
		top: 3px;
		left: -1.5px;
	}
}

.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner,
.odometer.odometer-theme-minimal.odometer-animating-up .odometer-ribbon-inner,
.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating .odometer-ribbon-inner,
.odometer.odometer-theme-minimal.odometer-animating-down.odometer-animating .odometer-ribbon-inner {
	transition-duration: 3.5s !important;
}

.lazy {
	opacity: 0;
}

.fancybox__slide {
	// background: var(--color-dark);
}
.fancybox__infobar {
	line-height: 2.3vw;
	font-size: 0.8vw;
	text-shadow: none;
	box-shadow: none;
	padding: 0 1vw;
}
:root {
	--f-brand-color: rgb(172, 170, 170);
}
.fancybox__thumbs.is-classic.is-vertical {
	outline: 1px solid #807e7e;
}
#productCarousel,
.f-thumbs.is-classic,
.fancybox__toolbar,
.fancybox__nav {
	--f-button-width: clamp(42px, 2.6vw, 48px);
	--f-button-height: clamp(42px, 2.6vw, 48px);
	--f-button-border-radius: clamp(6px, 0.5vw, 9px);

	--f-button-color: #0d0c22;
	--f-button-hover-color: var(--f-brand-color);

	--f-button-bg: var(--color-dark);
	--f-button-hover-bg: #252525;
	--f-button-active-bg: #252525;

	--f-button-svg-width: clamp(12px, 0.9vw, 19px);
	--f-button-svg-height: clamp(12px, 0.9vw, 18px);
	--f-button-svg-stroke-width: 2;
	--f-button-svg-filter: none;

	--f-button-svg-disabled-opacity: 0.2;
	@include md {
		--f-button-width: clamp(32px, 2.6vw, 48px);
		--f-button-height: clamp(32px, 2.6vw, 48px);
	}
}

.f-button svg {
	stroke: var(--color-white) !important;
	@include md {
		width: 20px !important;
		height: 20px !important;
	}
}
.f-carousel__slide {
	display: flex;
	align-items: center;
	justify-items: center;
}

.f-thumbs.is-classic {
	--f-thumb-width: 120px;
	--f-thumb-height: 80px;
	--f-thumb-gap: 8px;

	--f-thumb-opacity: 1;
	--f-thumb-selected-opacity: 1;

	--f-thumb-outline: 2px;
	--f-thumb-outline-color: var(--f-brand-color);

	--f-button-next-pos: 8px;
	--f-button-prev-pos: 8px;
}

.f-thumbs.is-classic.is-horizontal {
	padding: 0;
}

.f-thumbs.is-classic.is-vertical {
	padding: 4vw 0;
}
.f-thumbs__track {
	@include md {
		justify-content: flex-start;
	}
}
.f-thumbs.is-horizontal .f-thumbs__track {
	@include md {
		padding: 16px 8px;
	}
}
#productCarousel .f-carousel__nav {
	opacity: 0;
	transition: opacity 0.15s;
}

@media (hover: hover) {
	#productCarousel:hover .f-carousel__nav {
		opacity: 1;
	}
}

.fancybox__container {
	--fancybox-bg: var(--color-gray-450);
	--fancybox-color: #6a6969;

	--f-spinner-color-1: rgba(0, 0, 0, 0.1);
	--f-spinner-color-2: rgba(17, 24, 28, 0.8);
}

@media screen and (min-width: 640px) {
	.fancybox__container {
		flex-direction: row-reverse;
	}
}

.fancybox__thumbs.is-classic.is-horizontal {
	--f-thumb-width: 75px;
	--f-thumb-height: 55px;
	--f-thumb-gap: 0.5px;
}
.f-thumbs.is-classic {
	--f-thumb-outline: 1px;
}
.f-thumbs__slide {
	position: relative;
	&::before {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		transition: 0.4s ease;
		left: 0;
		background: rgba(256, 256, 256, 0.5);
	}
	&.is-nav-selected {
		&::before {
			opacity: 0;
			visibility: hidden;
		}
	}
}
.fancybox__thumbs.is-classic.is-vertical {
	--f-thumb-width: 100%;
	border-radius: 1vw;
	--f-thumb-height: 6vw;

	width: 9vw;

	outline: 1px solid #edeef0;
}

.fancybox__thumbs.is-classic.is-vertical .f-thumbs__track {
	padding: 0 1vw;
}

.fancybox__toolbar {
	padding: 8px;
}
.f-carousel__nav {
	@include md {
		display: none;
	}
}
.fancybox__nav {
	--f-button-next-pos: 1vw;
	--f-button-prev-pos: 1vw;
	@include md {
		--f-button-next-pos: 8px;
		--f-button-prev-pos: 8px;
	}
}
.fancybox-instagram {
	.fancybox-image {
		max-width: 48vw;
		height: calc(100% - 2vw);
		@include md {
			max-width: 100%;
			height: 100%;
			width: calc(100% - 30px);
		}
	}
}
.fancybox-tiktok {
	.fancybox-image {
		max-width: 48vw;
		height: calc(100% - 2vw);
		@include md {
			max-width: 100%;
			height: 100%;
			width: calc(100% - 30px);
		}
	}
}

.fancybox__toolbar.is-absolute,
.is-compact .fancybox__toolbar {
	padding: 1vw;
	@include md {
		padding: 8px;
	}
}

.scroll-platform {
	position: fixed;
	bottom: 2vw;
	z-index: 1;
	right: 3vw;
	width: 2.5vw;
	height: 2.5vw;
	border-radius: 50%;
	display: flex;
	visibility: hidden;
	opacity: 0;
	background-color: #1c1c1c;
	cursor: pointer;
	justify-content: center;
	align-items: center;
	transition: 0.9s cubic-bezier(0.165, 0.84, 0.44, 1);
	@include md {
		width: 2.5rem;
		height: 2.5rem;
		right: 1.6rem;
		bottom: 1.6rem;
		left: auto;
	}
	&.active {
		opacity: 1;
		visibility: visible;
	}
	svg {
		fill: var(--color-white);
		width: 0.7vw;
		transform: rotate(180deg);
		height: 0.7vw;
		display: block;
		@include md {
			width: 12px;
			height: 12px;
		}
	}
}

.MuiDayCalendarSkeleton-root {
	width: 100%;
	// height: 20vw;
}
.MuiDayCalendarSkeleton-week {
}
.MuiDayCalendarSkeleton-daySkeleton {
	background: var(--color-dark-800) !important;
	width: 100% !important;
	height: 3.2vw !important;
	border-radius: 0.5vw !important;
	margin: 0.2vw !important;
	@include xl {
		width: 120px !important;
		height: 40px !important;
		border-radius: 12px !important;
	}
	@include sm {
		width: 100px !important;
		border-radius: 10px !important;
		height: 32px !important;
	}
}

.css-zrqpq1::before {
	display: none;
}
.css-zrqpq1::after {
	display: none;
}

div {
	&.nav-active {
		.nav-header__logo img {
			filter: invert(1);
		}
	}
}

.fancybox__slide {
	// background: var(--color-dark);
}
.fancybox__infobar {
	line-height: 2.3vw;
	font-size: 0.8vw;
	text-shadow: none;
	box-shadow: none;
	padding: 0 1vw;
}
:root {
	--f-brand-color: rgb(172, 170, 170);
}
.fancybox__thumbs.is-classic.is-vertical {
	outline: 1px solid #807e7e;
}
#productCarousel,
.f-thumbs.is-classic,
.fancybox__toolbar,
.fancybox__nav {
	--f-button-width: 2.4vw;
	--f-button-height: 2.4vw;
	--f-button-border-radius: 0.5vw;

	--f-button-color: #0d0c22;
	--f-button-hover-color: var(--f-brand-color);

	--f-button-bg: var(--color-dark);
	--f-button-hover-bg: #252525;
	--f-button-active-bg: #252525;

	--f-button-svg-width: 0.9vw;
	--f-button-svg-height: 0.9vw;
	--f-button-svg-stroke-width: 2;
	--f-button-svg-filter: none;

	--f-button-svg-disabled-opacity: 0.2;
	@include md {
		--f-button-width: 32px;
		--f-button-height: 32px;
		--f-button-svg-width: 12px;
		--f-button-svg-height: 12px;
		--f-button-border-radius: 6px;
	}
}
.f-thumbs.is-horizontal .f-thumbs__slide {
}
.f-button svg {
	stroke: var(--color-white) !important;
}
.fancybox__container {
	background: rgba(12, 12, 12, 0.9) !important;
}

.f-carousel__slide {
	display: flex;
	align-items: center;
	justify-items: center;
}

.f-thumbs.is-classic {
	--f-thumb-width: 120px;
	--f-thumb-height: 80px;
	--f-thumb-gap: 8px;

	--f-thumb-opacity: 1;
	--f-thumb-selected-opacity: 1;

	--f-thumb-outline: 2px;
	--f-thumb-outline-color: var(--f-brand-color);

	--f-button-next-pos: 8px;
	--f-button-prev-pos: 8px;
}

.f-thumbs.is-classic.is-horizontal {
	padding: 0;
}

.f-thumbs.is-classic.is-vertical {
	padding: 3vw 0;
}
.f-thumbs__track {
	@include md {
		justify-content: flex-start;
	}
}
.f-thumbs.is-horizontal .f-thumbs__track {
	@include md {
		padding: 16px 8px;
	}
}
#productCarousel .f-carousel__nav {
	opacity: 0;
	transition: opacity 0.15s;
}

@media (hover: hover) {
	#productCarousel:hover .f-carousel__nav {
		opacity: 1;
	}
}

.fancybox__container {
	--fancybox-bg: var(--color-gray-450);
	--fancybox-color: #6a6969;

	--f-spinner-color-1: rgba(0, 0, 0, 0.1);
	--f-spinner-color-2: rgba(17, 24, 28, 0.8);
}

@media screen and (min-width: 640px) {
	.fancybox__container {
		flex-direction: row-reverse;
	}
}

.fancybox__thumbs.is-classic.is-horizontal {
	--f-thumb-width: 75px;
	--f-thumb-height: 55px;
	--f-thumb-gap: 0.5px;
}
.f-thumbs.is-classic {
	--f-thumb-outline: 1px;
}
.f-thumbs__slide {
	position: relative;
	&::before {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		transition: 0.4s ease;
		left: 0;
		background: rgba(256, 256, 256, 0.5);
	}
	&.is-nav-selected {
		&::before {
			opacity: 0;
			visibility: hidden;
		}
	}
}
.fancybox__thumbs.is-classic.is-vertical {
	--f-thumb-width: 100%;
	border-radius: 1vw;
	--f-thumb-height: 6vw;

	width: 9vw;

	outline: 1px solid #edeef0;
}

.fancybox__thumbs.is-classic.is-vertical .f-thumbs__track {
	padding: 0 1vw;
}

.fancybox__toolbar {
	padding: 8px;
}
.f-carousel__nav {
	@include md {
		display: none;
	}
}
.fancybox__nav {
	--f-button-next-pos: 1vw;
	--f-button-prev-pos: 1vw;
	@include md {
		--f-button-next-pos: 8px;
		--f-button-prev-pos: 8px;
	}
}
.fancybox-instagram {
	.fancybox-image {
		max-width: 47vw;
		height: calc(100% - 4vw);
		@include md {
			max-width: 100%;
			height: 100%;
			width: calc(100% - 30px);
		}
	}
}

.fancybox__toolbar.is-absolute,
.is-compact .fancybox__toolbar {
	padding: 1vw;
	@include md {
		padding: 8px;
	}
}

.f-button.f-button {
	background: #232323a6 !important;
	border-radius: 4px !important;
	@include md {
		width: 40px;
		height: 40px;
	}
}
.fancybox__slide.has-image.is-animating,
.fancybox__slide.has-image.is-selected {
	padding: clamp(40px, 3vw, 60px);
	border-radius: 10px;
	overflow: hidden;
}

.LazyLoad {
	display: block;
	width: 100%;
	height: 100%;
	opacity: 0;
	transition: 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);

	&.is-visible {
		transition-delay: 0.6s;
		transition-duration: 1.6s;
		opacity: 1;
		transform: none;
		@include md {
			transition-delay: 0.3s;
			transition-duration: 1.5s;
		}
	}

	&.lazy-image {
		.studio-faces__figure {
			background: var(--color-gray-450);
			border-radius: clamp(8px, 0.5vw, 10px);
		}
		&.is-visible {
			.studio-faces__figure {
				transform: scale(1);
				opacity: 1;
			}
			img,
			video {
				transform: scale(1);
			}
		}
	}
}
